import { useEffect, useState, useRef } from 'react';
import styles from './Home.module.css'
import Cela from '../../components/Cela'
import { Table } from 'reactstrap';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import logo from '../../assets/img/logo.png'
import { FaSearch } from "react-icons/fa";
import userDef from '../../assets/img/User-Default3.jpg'
import { FaEye } from "react-icons/fa";
import Loading from '../../components/Loading';
import { Skeleton, styled } from '@mui/material';


const ShinySkeleton = styled(Skeleton)(({ theme }) => ({
  background: 'linear-gradient(90deg, rgba(255,255,255,0.05) 25%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.05) 75%)',
  backgroundSize: '200% 100%',
  animation: 'shine 1.5s infinite',
  '@keyframes shine': {
    '0%': {
      backgroundPosition: '-200% 0',
    },
    '100%': {
      backgroundPosition: '200% 0',
    },
  },
}));

const Home = ({ userVerificacao, galerias }) => {
  const [pagina, setPagina] = useState(1)
  const [pessoasDomiciliar, setPessoasDomiciliar] = useState('')
  const [pessoasHospital, setPessoasHospital] = useState('')
  const [pessoasPernoite, setPessoasPernoite] = useState('')
  const [modalBuscaAberto, setModalBuscaAberto] = useState(false);
  const [termoBusca, setTermoBusca] = useState('');
  const [resultadosBusca, setResultadosBusca] = useState([]);
  const [quadranteA, setQuadranteA] = useState(1)
  const [quadranteB, setQuadranteB] = useState(1)
  const [quadranteC, setQuadranteC] = useState(1)
  const [ativaBotao, setAtivaBotao] = useState(1)
  const [usuario] = useState(userVerificacao)
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(false)
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const [nomeBuscado, setNomeBuscado] = useState('')

  const inputBuscaRef = useRef(null);

  useEffect(() => {
    if (modalBuscaAberto) {
      const timer = setTimeout(() => {
        inputBuscaRef.current.focus();
      }, 500);

      // Limpar o timer quando o componente for desmontado ou se modalBuscaAberto mudar
      return () => clearTimeout(timer);
    }
  }, [modalBuscaAberto]);


  const scrollToCoordinates = (cela) => {
    const targetElement = document.getElementById(cela);

    if (targetElement) {
      // Rolar até o elemento
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const handleCliqueBuscaCela = (cela, nome) => {
    setNomeBuscado(nome)
    const primeiraLetra = cela.charAt(0);
    const segundaletra = cela.charAt(1);




    if (primeiraLetra == 'A') {

      paginaGaleriaA()
      if (segundaletra == 1) {
        setQuadranteA(1)
      } else {
        setQuadranteA(2)
      }
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);


    }
    if (primeiraLetra == 'A') {


      if (segundaletra == 'L') {
        paginaGaleriaAlojamento()
        setModalBuscaAberto(!modalBuscaAberto)
        setTimeout(() => {
          scrollToCoordinates(cela)
        }, 500);

      }
    }
    if (primeiraLetra == 'B') {
      paginaGaleriaB()
      if (segundaletra == 1) {
        setQuadranteB(1)
      } else {
        setQuadranteB(2)
      }
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'C') {
      paginaGaleriaC()
      if (segundaletra == 1) {
        setQuadranteC(1)
      } else {
        setQuadranteC(2)
      }
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'D') {
      paginaGaleriaD()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'E') {
      paginaGaleriaE()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'F') {
      paginaGaleriaF()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'G') {
      paginaGaleriaG()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'H') {
      paginaGaleriaH()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }

    if (primeiraLetra == 'T') {
      paginaGaleriaTR()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }

    if (segundaletra == 'O') {
      paginaForaUP()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'P') {
      paginaForaUP()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
  };


  const toggleModalBusca = () => {
    setTermoBusca('')
    setModalBuscaAberto(!modalBuscaAberto);
    setResultadosBusca([])
  };


  useEffect(() => {
    if (termoBusca === "SEM INFOPEN") {
      // Caso o termo de busca seja "SEM INFOPEN", filtre os registros onde infopen é 0
      setResultadosBusca(galerias.filter(item => item.infopen == 0));
      return; // Retorne para evitar a execução do restante do código
    }

    if (termoBusca.length < 3 || !galerias) {
      setResultadosBusca([]);
      return; // Retorne para evitar a execução do restante do código
    }

    // O restante do código permanece inalterado
    const termoNumerico = parseInt(termoBusca);

    const resultados = galerias.filter(item => {
      const infopenStr = item.infopen; // Converte infopen para string

      return (
        (typeof item.nome === 'string' && item.nome.includes(termoBusca)) ||
        (typeof item.selectDate === 'string' && item.selectDate.includes(termoBusca)) ||
        (typeof item.cela === 'string' && item.cela.includes(termoBusca)) ||
        (typeof item.tipo === 'string' && item.tipo.includes(termoBusca)) ||
        (typeof item.artigo === 'string' && item.artigo.includes(termoBusca)) ||
        (typeof item.visita === 'string' && userVerificacao.includes("chefeseg") && item.visita.includes(termoBusca)) ||
        (typeof item.visita === 'string' && userVerificacao.includes("diretor") && item.visita.includes(termoBusca)) ||
        (typeof item.selecao === 'string' && item.selecao.includes(termoBusca)) ||
        (typeof item.infopen === 'string' && infopenStr.includes(termoBusca)) || // Busca por string
        (typeof item.infopen === 'number' && item.infopen === termoNumerico) // Busca por número
      );
    });

    // Atualizar os resultados da busca
    setResultadosBusca(resultados);
  }, [galerias, termoBusca]);


  // criando componente que vai até o topo do quarante com o botão




  // criação das variáveis MAP GALERIA A
  const [pessoasSearchMapA01, setPessoasSearchMapA01] = useState('')
  const [pessoasSearchMapA02, setPessoasSearchMapA02] = useState('')
  const [pessoasSearchMapA03, setPessoasSearchMapA03] = useState('')
  const [pessoasSearchMapA04, setPessoasSearchMapA04] = useState('')
  const [pessoasSearchMapA05, setPessoasSearchMapA05] = useState('')
  const [pessoasSearchMapA06, setPessoasSearchMapA06] = useState('')
  const [pessoasSearchMapA07, setPessoasSearchMapA07] = useState('')
  const [pessoasSearchMapA08, setPessoasSearchMapA08] = useState('')
  const [pessoasSearchMapA09, setPessoasSearchMapA09] = useState('')
  const [pessoasSearchMapA10, setPessoasSearchMapA10] = useState('')
  const [pessoasSearchMapA11, setPessoasSearchMapA11] = useState('')
  const [pessoasSearchMapA12, setPessoasSearchMapA12] = useState('')
  const [pessoasSearchMapA13, setPessoasSearchMapA13] = useState('')
  const [pessoasSearchMapA14, setPessoasSearchMapA14] = useState('')
  const [pessoasSearchMapA15, setPessoasSearchMapA15] = useState('')
  const [pessoasSearchMapA16, setPessoasSearchMapA16] = useState('')
  const [pessoasSearchMapA17, setPessoasSearchMapA17] = useState('')
  const [pessoasSearchMapA18, setPessoasSearchMapA18] = useState('')
  const [pessoasSearchMapA19, setPessoasSearchMapA19] = useState('')
  const [pessoasSearchMapA20, setPessoasSearchMapA20] = useState('')


  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB01, setPessoasSearchMapB01] = useState('')
  const [pessoasSearchMapB02, setPessoasSearchMapB02] = useState('')
  const [pessoasSearchMapB03, setPessoasSearchMapB03] = useState('')
  const [pessoasSearchMapB04, setPessoasSearchMapB04] = useState('')
  const [pessoasSearchMapB05, setPessoasSearchMapB05] = useState('')
  const [pessoasSearchMapB06, setPessoasSearchMapB06] = useState('')
  const [pessoasSearchMapB07, setPessoasSearchMapB07] = useState('')
  const [pessoasSearchMapB08, setPessoasSearchMapB08] = useState('')
  const [pessoasSearchMapB09, setPessoasSearchMapB09] = useState('')
  const [pessoasSearchMapB10, setPessoasSearchMapB10] = useState('')
  const [pessoasSearchMapB11, setPessoasSearchMapB11] = useState('')
  const [pessoasSearchMapB12, setPessoasSearchMapB12] = useState('')
  const [pessoasSearchMapB13, setPessoasSearchMapB13] = useState('')
  const [pessoasSearchMapB14, setPessoasSearchMapB14] = useState('')
  const [pessoasSearchMapB15, setPessoasSearchMapB15] = useState('')
  const [pessoasSearchMapB16, setPessoasSearchMapB16] = useState('')
  const [pessoasSearchMapB17, setPessoasSearchMapB17] = useState('')
  const [pessoasSearchMapB18, setPessoasSearchMapB18] = useState('')
  const [pessoasSearchMapB19, setPessoasSearchMapB19] = useState('')
  const [pessoasSearchMapB20, setPessoasSearchMapB20] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC01, setPessoasSearchMapC01] = useState('')
  const [pessoasSearchMapC02, setPessoasSearchMapC02] = useState('')
  const [pessoasSearchMapC03, setPessoasSearchMapC03] = useState('')
  const [pessoasSearchMapC04, setPessoasSearchMapC04] = useState('')
  const [pessoasSearchMapC05, setPessoasSearchMapC05] = useState('')
  const [pessoasSearchMapC06, setPessoasSearchMapC06] = useState('')
  const [pessoasSearchMapC07, setPessoasSearchMapC07] = useState('')
  const [pessoasSearchMapC08, setPessoasSearchMapC08] = useState('')
  const [pessoasSearchMapC09, setPessoasSearchMapC09] = useState('')
  const [pessoasSearchMapC10, setPessoasSearchMapC10] = useState('')
  const [pessoasSearchMapC11, setPessoasSearchMapC11] = useState('')
  const [pessoasSearchMapC12, setPessoasSearchMapC12] = useState('')
  const [pessoasSearchMapC13, setPessoasSearchMapC13] = useState('')
  const [pessoasSearchMapC14, setPessoasSearchMapC14] = useState('')
  const [pessoasSearchMapC15, setPessoasSearchMapC15] = useState('')
  const [pessoasSearchMapC16, setPessoasSearchMapC16] = useState('')
  const [pessoasSearchMapC17, setPessoasSearchMapC17] = useState('')
  const [pessoasSearchMapC18, setPessoasSearchMapC18] = useState('')
  const [pessoasSearchMapC19, setPessoasSearchMapC19] = useState('')
  const [pessoasSearchMapC20, setPessoasSearchMapC20] = useState('')


  // CRIAÇÃO DAS VARIAVEIS GALERIA D (DD + DE)

  const [pessoasSearchMapDD01, setPessoasSearchMapDD01] = useState('')
  const [pessoasSearchMapDD02, setPessoasSearchMapDD02] = useState('')
  const [pessoasSearchMapDD03, setPessoasSearchMapDD03] = useState('')
  const [pessoasSearchMapDD04, setPessoasSearchMapDD04] = useState('')
  const [pessoasSearchMapDD05, setPessoasSearchMapDD05] = useState('')

  const [pessoasSearchMapDE01, setPessoasSearchMapDE01] = useState('')
  const [pessoasSearchMapDE02, setPessoasSearchMapDE02] = useState('')
  const [pessoasSearchMapDE03, setPessoasSearchMapDE03] = useState('')
  const [pessoasSearchMapDE04, setPessoasSearchMapDE04] = useState('')
  const [pessoasSearchMapDE05, setPessoasSearchMapDE05] = useState('')


  // GALERIA E

  const [pessoasSearchMapE01, setPessoasSearchMapE01] = useState('')
  const [pessoasSearchMapE02, setPessoasSearchMapE02] = useState('')
  const [pessoasSearchMapE03, setPessoasSearchMapE03] = useState('')
  const [pessoasSearchMapE04, setPessoasSearchMapE04] = useState('')
  const [pessoasSearchMapE05, setPessoasSearchMapE05] = useState('')
  const [pessoasSearchMapE06, setPessoasSearchMapE06] = useState('')
  const [pessoasSearchMapE07, setPessoasSearchMapE07] = useState('')
  const [pessoasSearchMapE08, setPessoasSearchMapE08] = useState('')
  const [pessoasSearchMapE09, setPessoasSearchMapE09] = useState('')
  const [pessoasSearchMapE10, setPessoasSearchMapE10] = useState('')
  const [pessoasSearchMapE11, setPessoasSearchMapE11] = useState('')
  const [pessoasSearchMapE12, setPessoasSearchMapE12] = useState('')
  const [pessoasSearchMapE13, setPessoasSearchMapE13] = useState('')
  const [pessoasSearchMapE14, setPessoasSearchMapE14] = useState('')
  const [pessoasSearchMapE15, setPessoasSearchMapE15] = useState('')
  const [pessoasSearchMapE16, setPessoasSearchMapE16] = useState('')
  const [pessoasSearchMapE17, setPessoasSearchMapE17] = useState('')
  const [pessoasSearchMapE18, setPessoasSearchMapE18] = useState('')
  const [pessoasSearchMapE19, setPessoasSearchMapE19] = useState('')
  const [pessoasSearchMapE20, setPessoasSearchMapE20] = useState('')

  // GALERIA F

  const [pessoasSearchMapF01, setPessoasSearchMapF01] = useState('')
  const [pessoasSearchMapF02, setPessoasSearchMapF02] = useState('')
  const [pessoasSearchMapF03, setPessoasSearchMapF03] = useState('')
  const [pessoasSearchMapF04, setPessoasSearchMapF04] = useState('')
  const [pessoasSearchMapF05, setPessoasSearchMapF05] = useState('')
  const [pessoasSearchMapF06, setPessoasSearchMapF06] = useState('')
  const [pessoasSearchMapF07, setPessoasSearchMapF07] = useState('')
  const [pessoasSearchMapF08, setPessoasSearchMapF08] = useState('')
  const [pessoasSearchMapF09, setPessoasSearchMapF09] = useState('')
  const [pessoasSearchMapF10, setPessoasSearchMapF10] = useState('')
  const [pessoasSearchMapF11, setPessoasSearchMapF11] = useState('')
  const [pessoasSearchMapF12, setPessoasSearchMapF12] = useState('')
  const [pessoasSearchMapF13, setPessoasSearchMapF13] = useState('')
  const [pessoasSearchMapF14, setPessoasSearchMapF14] = useState('')
  const [pessoasSearchMapF15, setPessoasSearchMapF15] = useState('')
  const [pessoasSearchMapF16, setPessoasSearchMapF16] = useState('')
  const [pessoasSearchMapF17, setPessoasSearchMapF17] = useState('')
  const [pessoasSearchMapF18, setPessoasSearchMapF18] = useState('')
  const [pessoasSearchMapF19, setPessoasSearchMapF19] = useState('')
  const [pessoasSearchMapF20, setPessoasSearchMapF20] = useState('')


  // GALERIA G
  const [pessoasSearchMapG01, setPessoasSearchMapG01] = useState('')
  const [pessoasSearchMapG02, setPessoasSearchMapG02] = useState('')
  const [pessoasSearchMapG03, setPessoasSearchMapG03] = useState('')
  const [pessoasSearchMapG04, setPessoasSearchMapG04] = useState('')
  const [pessoasSearchMapG05, setPessoasSearchMapG05] = useState('')
  const [pessoasSearchMapG06, setPessoasSearchMapG06] = useState('')
  const [pessoasSearchMapG07, setPessoasSearchMapG07] = useState('')
  const [pessoasSearchMapG08, setPessoasSearchMapG08] = useState('')
  const [pessoasSearchMapG09, setPessoasSearchMapG09] = useState('')
  const [pessoasSearchMapG10, setPessoasSearchMapG10] = useState('')
  const [pessoasSearchMapG11, setPessoasSearchMapG11] = useState('')
  const [pessoasSearchMapG12, setPessoasSearchMapG12] = useState('')
  const [pessoasSearchMapG13, setPessoasSearchMapG13] = useState('')
  const [pessoasSearchMapG14, setPessoasSearchMapG14] = useState('')
  const [pessoasSearchMapG15, setPessoasSearchMapG15] = useState('')
  const [pessoasSearchMapG16, setPessoasSearchMapG16] = useState('')
  const [pessoasSearchMapG17, setPessoasSearchMapG17] = useState('')
  const [pessoasSearchMapG18, setPessoasSearchMapG18] = useState('')
  const [pessoasSearchMapG19, setPessoasSearchMapG19] = useState('')
  const [pessoasSearchMapG20, setPessoasSearchMapG20] = useState('')


  // GALERIA H

  const [pessoasSearchMapHD01, setPessoasSearchMapHD01] = useState('')
  const [pessoasSearchMapHD02, setPessoasSearchMapHD02] = useState('')
  const [pessoasSearchMapHD03, setPessoasSearchMapHD03] = useState('')
  const [pessoasSearchMapHD04, setPessoasSearchMapHD04] = useState('')
  const [pessoasSearchMapHD05, setPessoasSearchMapHD05] = useState('')

  const [pessoasSearchMapHE01, setPessoasSearchMapHE01] = useState('')
  const [pessoasSearchMapHE02, setPessoasSearchMapHE02] = useState('')
  const [pessoasSearchMapHE03, setPessoasSearchMapHE03] = useState('')
  const [pessoasSearchMapHE04, setPessoasSearchMapHE04] = useState('')
  const [pessoasSearchMapHE05, setPessoasSearchMapHE05] = useState('')


  //variaveis da triagem

  const [pessoasSearchMapTR, setPessoasSearchMapTR] = useState('')
  const [pessoasSearchMapTR01, setPessoasSearchMapTR01] = useState('')
  const [pessoasSearchMapTR02, setPessoasSearchMapTR02] = useState('')
  const [pessoasSearchMapTR03, setPessoasSearchMapTR03] = useState('')
  const [pessoasSearchMapTR04, setPessoasSearchMapTR04] = useState('')
  const [pessoasSearchMapTR05, setPessoasSearchMapTR05] = useState('')
  const [pessoasSearchMapTR06, setPessoasSearchMapTR06] = useState('')


  // ALOJAMENTO

  const [pessoasSearchMapALOJAMENTO, setPessoasSearchMapALOJAMENTO] = useState('')


  //CRIAÇÃO DAS VARIÁVEIS DE PRESOS FORA DA UNIDADE

  const [pessoasDomiciliartotal, setPessoasDomiciliartotal] = useState('')
  const [pessoasHospitaltotal, setPessoasHospitaltotal] = useState('')
  const [pessoasPernoitetotal, setPessoasPernoitetotal] = useState('')



  // criação das variáveis de totalização do componente Cela
  const [pessoasSearchMapA01total, setPessoasSearchMapA01total] = useState('')
  const [pessoasSearchMapA02total, setPessoasSearchMapA02total] = useState('')
  const [pessoasSearchMapA03total, setPessoasSearchMapA03total] = useState('')
  const [pessoasSearchMapA04total, setPessoasSearchMapA04total] = useState('')
  const [pessoasSearchMapA05total, setPessoasSearchMapA05total] = useState('')
  const [pessoasSearchMapA06total, setPessoasSearchMapA06total] = useState('')
  const [pessoasSearchMapA07total, setPessoasSearchMapA07total] = useState('')
  const [pessoasSearchMapA08total, setPessoasSearchMapA08total] = useState('')
  const [pessoasSearchMapA09total, setPessoasSearchMapA09total] = useState('')
  const [pessoasSearchMapA10total, setPessoasSearchMapA10total] = useState('')
  const [pessoasSearchMapA11total, setPessoasSearchMapA11total] = useState('')
  const [pessoasSearchMapA12total, setPessoasSearchMapA12total] = useState('')
  const [pessoasSearchMapA13total, setPessoasSearchMapA13total] = useState('')
  const [pessoasSearchMapA14total, setPessoasSearchMapA14total] = useState('')
  const [pessoasSearchMapA15total, setPessoasSearchMapA15total] = useState('')
  const [pessoasSearchMapA16total, setPessoasSearchMapA16total] = useState('')
  const [pessoasSearchMapA17total, setPessoasSearchMapA17total] = useState('')
  const [pessoasSearchMapA18total, setPessoasSearchMapA18total] = useState('')
  const [pessoasSearchMapA19total, setPessoasSearchMapA19total] = useState('')
  const [pessoasSearchMapA20total, setPessoasSearchMapA20total] = useState('')

  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB01total, setPessoasSearchMapB01total] = useState('')
  const [pessoasSearchMapB02total, setPessoasSearchMapB02total] = useState('')
  const [pessoasSearchMapB03total, setPessoasSearchMapB03total] = useState('')
  const [pessoasSearchMapB04total, setPessoasSearchMapB04total] = useState('')
  const [pessoasSearchMapB05total, setPessoasSearchMapB05total] = useState('')
  const [pessoasSearchMapB06total, setPessoasSearchMapB06total] = useState('')
  const [pessoasSearchMapB07total, setPessoasSearchMapB07total] = useState('')
  const [pessoasSearchMapB08total, setPessoasSearchMapB08total] = useState('')
  const [pessoasSearchMapB09total, setPessoasSearchMapB09total] = useState('')
  const [pessoasSearchMapB10total, setPessoasSearchMapB10total] = useState('')
  const [pessoasSearchMapB11total, setPessoasSearchMapB11total] = useState('')
  const [pessoasSearchMapB12total, setPessoasSearchMapB12total] = useState('')
  const [pessoasSearchMapB13total, setPessoasSearchMapB13total] = useState('')
  const [pessoasSearchMapB14total, setPessoasSearchMapB14total] = useState('')
  const [pessoasSearchMapB15total, setPessoasSearchMapB15total] = useState('')
  const [pessoasSearchMapB16total, setPessoasSearchMapB16total] = useState('')
  const [pessoasSearchMapB17total, setPessoasSearchMapB17total] = useState('')
  const [pessoasSearchMapB18total, setPessoasSearchMapB18total] = useState('')
  const [pessoasSearchMapB19total, setPessoasSearchMapB19total] = useState('')
  const [pessoasSearchMapB20total, setPessoasSearchMapB20total] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC01total, setPessoasSearchMapC01total] = useState('')
  const [pessoasSearchMapC02total, setPessoasSearchMapC02total] = useState('')
  const [pessoasSearchMapC03total, setPessoasSearchMapC03total] = useState('')
  const [pessoasSearchMapC04total, setPessoasSearchMapC04total] = useState('')
  const [pessoasSearchMapC05total, setPessoasSearchMapC05total] = useState('')
  const [pessoasSearchMapC06total, setPessoasSearchMapC06total] = useState('')
  const [pessoasSearchMapC07total, setPessoasSearchMapC07total] = useState('')
  const [pessoasSearchMapC08total, setPessoasSearchMapC08total] = useState('')
  const [pessoasSearchMapC09total, setPessoasSearchMapC09total] = useState('')
  const [pessoasSearchMapC10total, setPessoasSearchMapC10total] = useState('')
  const [pessoasSearchMapC11total, setPessoasSearchMapC11total] = useState('')
  const [pessoasSearchMapC12total, setPessoasSearchMapC12total] = useState('')
  const [pessoasSearchMapC13total, setPessoasSearchMapC13total] = useState('')
  const [pessoasSearchMapC14total, setPessoasSearchMapC14total] = useState('')
  const [pessoasSearchMapC15total, setPessoasSearchMapC15total] = useState('')
  const [pessoasSearchMapC16total, setPessoasSearchMapC16total] = useState('')
  const [pessoasSearchMapC17total, setPessoasSearchMapC17total] = useState('')
  const [pessoasSearchMapC18total, setPessoasSearchMapC18total] = useState('')
  const [pessoasSearchMapC19total, setPessoasSearchMapC19total] = useState('')
  const [pessoasSearchMapC20total, setPessoasSearchMapC20total] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA D

  const [pessoasSearchMapDD01total, setPessoasSearchMapDD01total] = useState('')
  const [pessoasSearchMapDD02total, setPessoasSearchMapDD02total] = useState('')
  const [pessoasSearchMapDD03total, setPessoasSearchMapDD03total] = useState('')
  const [pessoasSearchMapDD04total, setPessoasSearchMapDD04total] = useState('')
  const [pessoasSearchMapDD05total, setPessoasSearchMapDD05total] = useState('')
  const [pessoasSearchMapDE01total, setPessoasSearchMapDE01total] = useState('')
  const [pessoasSearchMapDE02total, setPessoasSearchMapDE02total] = useState('')
  const [pessoasSearchMapDE03total, setPessoasSearchMapDE03total] = useState('')
  const [pessoasSearchMapDE04total, setPessoasSearchMapDE04total] = useState('')
  const [pessoasSearchMapDE05total, setPessoasSearchMapDE05total] = useState('')

  //GALERIA E

  const [pessoasSearchMapE01total, setPessoasSearchMapE01total] = useState('')
  const [pessoasSearchMapE02total, setPessoasSearchMapE02total] = useState('')
  const [pessoasSearchMapE03total, setPessoasSearchMapE03total] = useState('')
  const [pessoasSearchMapE04total, setPessoasSearchMapE04total] = useState('')
  const [pessoasSearchMapE05total, setPessoasSearchMapE05total] = useState('')
  const [pessoasSearchMapE06total, setPessoasSearchMapE06total] = useState('')
  const [pessoasSearchMapE07total, setPessoasSearchMapE07total] = useState('')
  const [pessoasSearchMapE08total, setPessoasSearchMapE08total] = useState('')
  const [pessoasSearchMapE09total, setPessoasSearchMapE09total] = useState('')
  const [pessoasSearchMapE10total, setPessoasSearchMapE10total] = useState('')
  const [pessoasSearchMapE11total, setPessoasSearchMapE11total] = useState('')
  const [pessoasSearchMapE12total, setPessoasSearchMapE12total] = useState('')
  const [pessoasSearchMapE13total, setPessoasSearchMapE13total] = useState('')
  const [pessoasSearchMapE14total, setPessoasSearchMapE14total] = useState('')
  const [pessoasSearchMapE15total, setPessoasSearchMapE15total] = useState('')
  const [pessoasSearchMapE16total, setPessoasSearchMapE16total] = useState('')
  const [pessoasSearchMapE17total, setPessoasSearchMapE17total] = useState('')
  const [pessoasSearchMapE18total, setPessoasSearchMapE18total] = useState('')
  const [pessoasSearchMapE19total, setPessoasSearchMapE19total] = useState('')
  const [pessoasSearchMapE20total, setPessoasSearchMapE20total] = useState('')


  //GALERIA F

  const [pessoasSearchMapF01total, setPessoasSearchMapF01total] = useState('')
  const [pessoasSearchMapF02total, setPessoasSearchMapF02total] = useState('')
  const [pessoasSearchMapF03total, setPessoasSearchMapF03total] = useState('')
  const [pessoasSearchMapF04total, setPessoasSearchMapF04total] = useState('')
  const [pessoasSearchMapF05total, setPessoasSearchMapF05total] = useState('')
  const [pessoasSearchMapF06total, setPessoasSearchMapF06total] = useState('')
  const [pessoasSearchMapF07total, setPessoasSearchMapF07total] = useState('')
  const [pessoasSearchMapF08total, setPessoasSearchMapF08total] = useState('')
  const [pessoasSearchMapF09total, setPessoasSearchMapF09total] = useState('')
  const [pessoasSearchMapF10total, setPessoasSearchMapF10total] = useState('')
  const [pessoasSearchMapF11total, setPessoasSearchMapF11total] = useState('')
  const [pessoasSearchMapF12total, setPessoasSearchMapF12total] = useState('')
  const [pessoasSearchMapF13total, setPessoasSearchMapF13total] = useState('')
  const [pessoasSearchMapF14total, setPessoasSearchMapF14total] = useState('')
  const [pessoasSearchMapF15total, setPessoasSearchMapF15total] = useState('')
  const [pessoasSearchMapF16total, setPessoasSearchMapF16total] = useState('')
  const [pessoasSearchMapF17total, setPessoasSearchMapF17total] = useState('')
  const [pessoasSearchMapF18total, setPessoasSearchMapF18total] = useState('')
  const [pessoasSearchMapF19total, setPessoasSearchMapF19total] = useState('')
  const [pessoasSearchMapF20total, setPessoasSearchMapF20total] = useState('')

  // GALERIA G
  const [pessoasSearchMapG01total, setPessoasSearchMapG01total] = useState('')
  const [pessoasSearchMapG02total, setPessoasSearchMapG02total] = useState('')
  const [pessoasSearchMapG03total, setPessoasSearchMapG03total] = useState('')
  const [pessoasSearchMapG04total, setPessoasSearchMapG04total] = useState('')
  const [pessoasSearchMapG05total, setPessoasSearchMapG05total] = useState('')
  const [pessoasSearchMapG06total, setPessoasSearchMapG06total] = useState('')
  const [pessoasSearchMapG07total, setPessoasSearchMapG07total] = useState('')
  const [pessoasSearchMapG08total, setPessoasSearchMapG08total] = useState('')
  const [pessoasSearchMapG09total, setPessoasSearchMapG09total] = useState('')
  const [pessoasSearchMapG10total, setPessoasSearchMapG10total] = useState('')
  const [pessoasSearchMapG11total, setPessoasSearchMapG11total] = useState('')
  const [pessoasSearchMapG12total, setPessoasSearchMapG12total] = useState('')
  const [pessoasSearchMapG13total, setPessoasSearchMapG13total] = useState('')
  const [pessoasSearchMapG14total, setPessoasSearchMapG14total] = useState('')
  const [pessoasSearchMapG15total, setPessoasSearchMapG15total] = useState('')
  const [pessoasSearchMapG16total, setPessoasSearchMapG16total] = useState('')
  const [pessoasSearchMapG17total, setPessoasSearchMapG17total] = useState('')
  const [pessoasSearchMapG18total, setPessoasSearchMapG18total] = useState('')
  const [pessoasSearchMapG19total, setPessoasSearchMapG19total] = useState('')
  const [pessoasSearchMapG20total, setPessoasSearchMapG20total] = useState('')


  // GALERIA H 
  const [pessoasSearchMapHD01total, setPessoasSearchMapHD01total] = useState(0)
  const [pessoasSearchMapHD02total, setPessoasSearchMapHD02total] = useState(0)
  const [pessoasSearchMapHD03total, setPessoasSearchMapHD03total] = useState(0)
  const [pessoasSearchMapHD04total, setPessoasSearchMapHD04total] = useState(0)
  const [pessoasSearchMapHD05total, setPessoasSearchMapHD05total] = useState(0)
  const [pessoasSearchMapHE01total, setPessoasSearchMapHE01total] = useState(0)
  const [pessoasSearchMapHE02total, setPessoasSearchMapHE02total] = useState(0)
  const [pessoasSearchMapHE03total, setPessoasSearchMapHE03total] = useState(0)
  const [pessoasSearchMapHE04total, setPessoasSearchMapHE04total] = useState(0)
  const [pessoasSearchMapHE05total, setPessoasSearchMapHE05total] = useState(0)

  //variaveis da triagem


  const [pessoasSearchMapTRtotal, setPessoasSearchMapTRtotal] = useState('')
  const [pessoasSearchMapTR01total, setPessoasSearchMapTR01total] = useState('')
  const [pessoasSearchMapTR02total, setPessoasSearchMapTR02total] = useState('')
  const [pessoasSearchMapTR03total, setPessoasSearchMapTR03total] = useState('')
  const [pessoasSearchMapTR04total, setPessoasSearchMapTR04total] = useState('')
  const [pessoasSearchMapTR05total, setPessoasSearchMapTR05total] = useState('')
  const [pessoasSearchMapTR06total, setPessoasSearchMapTR06total] = useState('')

  const [pessoasSearchMapALOJAMENTOtotal, setPessoasSearchMapALOJAMENTOtotal] = useState('')

  // Criação das variáveis toalizadoras de valores
  const totalSomaA =
    pessoasSearchMapA01total +
    pessoasSearchMapA02total +
    pessoasSearchMapA03total +
    pessoasSearchMapA04total +
    pessoasSearchMapA05total +
    pessoasSearchMapA06total +
    pessoasSearchMapA07total +
    pessoasSearchMapA08total +
    pessoasSearchMapA09total +
    pessoasSearchMapA10total +
    pessoasSearchMapA11total +
    pessoasSearchMapA12total +
    pessoasSearchMapA13total +
    pessoasSearchMapA14total +
    pessoasSearchMapA15total +
    pessoasSearchMapA16total +
    pessoasSearchMapA17total +
    pessoasSearchMapA18total +
    pessoasSearchMapA19total +
    pessoasSearchMapA20total




  // GALERIA BRAVO 
  const totalSomaB =
    pessoasSearchMapB01total +
    pessoasSearchMapB02total +
    pessoasSearchMapB03total +
    pessoasSearchMapB04total +
    pessoasSearchMapB05total +
    pessoasSearchMapB06total +
    pessoasSearchMapB07total +
    pessoasSearchMapB08total +
    pessoasSearchMapB09total +
    pessoasSearchMapB10total +
    pessoasSearchMapB11total +
    pessoasSearchMapB12total +
    pessoasSearchMapB13total +
    pessoasSearchMapB14total +
    pessoasSearchMapB15total +
    pessoasSearchMapB16total +
    pessoasSearchMapB17total +
    pessoasSearchMapB18total +
    pessoasSearchMapB19total +
    pessoasSearchMapB20total


  // QUADRANTES BRAVO 


  // GALERIA CHARLIE


  const totalSomaC =
    pessoasSearchMapC01total +
    pessoasSearchMapC02total +
    pessoasSearchMapC03total +
    pessoasSearchMapC04total +
    pessoasSearchMapC05total +
    pessoasSearchMapC06total +
    pessoasSearchMapC07total +
    pessoasSearchMapC08total +
    pessoasSearchMapC09total +
    pessoasSearchMapC10total +
    pessoasSearchMapC11total +
    pessoasSearchMapC12total +
    pessoasSearchMapC13total +
    pessoasSearchMapC14total +
    pessoasSearchMapC15total +
    pessoasSearchMapC16total +
    pessoasSearchMapC17total +
    pessoasSearchMapC18total +
    pessoasSearchMapC19total +
    pessoasSearchMapC20total


  // QUADRANTES CHARLIE 







  const totalSomaD =

    pessoasSearchMapDD01total +
    pessoasSearchMapDD02total +
    pessoasSearchMapDD03total +
    pessoasSearchMapDD04total +
    pessoasSearchMapDD05total +
    pessoasSearchMapDE01total +
    pessoasSearchMapDE02total +
    pessoasSearchMapDE03total +
    pessoasSearchMapDE04total +
    pessoasSearchMapDE05total




  const totalSomaE =
    pessoasSearchMapE01total +
    pessoasSearchMapE02total +
    pessoasSearchMapE03total +
    pessoasSearchMapE04total +
    pessoasSearchMapE05total +
    pessoasSearchMapE06total +
    pessoasSearchMapE07total +
    pessoasSearchMapE08total +
    pessoasSearchMapE09total +
    pessoasSearchMapE10total +
    pessoasSearchMapE11total +
    pessoasSearchMapE12total +
    pessoasSearchMapE13total +
    pessoasSearchMapE14total +
    pessoasSearchMapE15total +
    pessoasSearchMapE16total +
    pessoasSearchMapE17total +
    pessoasSearchMapE18total +
    pessoasSearchMapE19total +
    pessoasSearchMapE20total

  const totalSomaF =
    pessoasSearchMapF01total +
    pessoasSearchMapF02total +
    pessoasSearchMapF03total +
    pessoasSearchMapF04total +
    pessoasSearchMapF05total +
    pessoasSearchMapF06total +
    pessoasSearchMapF07total +
    pessoasSearchMapF08total +
    pessoasSearchMapF09total +
    pessoasSearchMapF10total +
    pessoasSearchMapF11total +
    pessoasSearchMapF12total +
    pessoasSearchMapF13total +
    pessoasSearchMapF14total +
    pessoasSearchMapF15total +
    pessoasSearchMapF16total +
    pessoasSearchMapF17total +
    pessoasSearchMapF18total +
    pessoasSearchMapF19total +
    pessoasSearchMapF20total

  const totalSomaG =
    pessoasSearchMapG01total +
    pessoasSearchMapG02total +
    pessoasSearchMapG03total +
    pessoasSearchMapG04total +
    pessoasSearchMapG05total +
    pessoasSearchMapG06total +
    pessoasSearchMapG07total +
    pessoasSearchMapG08total +
    pessoasSearchMapG09total +
    pessoasSearchMapG10total +
    pessoasSearchMapG11total +
    pessoasSearchMapG12total +
    pessoasSearchMapG13total +
    pessoasSearchMapG14total +
    pessoasSearchMapG15total +
    pessoasSearchMapG16total +
    pessoasSearchMapG17total +
    pessoasSearchMapG18total +
    pessoasSearchMapG19total +
    pessoasSearchMapG20total

  const totalSomaH =

    pessoasSearchMapHD01total +
    pessoasSearchMapHD02total +
    pessoasSearchMapHD03total +
    pessoasSearchMapHD04total +
    pessoasSearchMapHD05total +
    pessoasSearchMapHE01total +
    pessoasSearchMapHE02total +
    pessoasSearchMapHE03total +
    pessoasSearchMapHE04total +
    pessoasSearchMapHE05total

  const totalSomaTR =
    pessoasSearchMapTRtotal +
    pessoasSearchMapTR01total +
    pessoasSearchMapTR02total +
    pessoasSearchMapTR03total +
    pessoasSearchMapTR04total +
    pessoasSearchMapTR05total +
    pessoasSearchMapTR06total


  const totalUp =
    totalSomaA +
    totalSomaB +
    totalSomaC +
    totalSomaD +
    totalSomaE +
    totalSomaF +
    totalSomaG +
    totalSomaH +
    totalSomaTR +
    pessoasSearchMapALOJAMENTOtotal

  const totalGeral = totalUp + pessoasDomiciliartotal + pessoasHospitaltotal + pessoasPernoitetotal


  useEffect(() => {
    if (totalGeral > 0) {
      setTimeout(() => {

        setIsSkeletonLoading(false);
      }, 1000);
    }
  }, [totalGeral]);





  const paginaTotal = () => {

    setAtivaBotao(1)
    setPagina(1)


  }
  const paginaGaleriaA = () => {
    setAtivaBotao(2)
    setPagina(2)

  }
  const paginaGaleriaB = () => {
    setAtivaBotao(3)
    setPagina(3)

  }
  const paginaGaleriaC = () => {

    setAtivaBotao(4)
    setPagina(4)

  }
  const paginaGaleriaD = () => {

    setAtivaBotao(5)
    setPagina(5)

  }
  const paginaGaleriaE = () => {

    setAtivaBotao(9)
    setPagina(9)

  }
  const paginaGaleriaF = () => {

    setAtivaBotao(10)
    setPagina(10)

  }
  const paginaGaleriaG = () => {

    setAtivaBotao(11)
    setPagina(11)

  }
  const paginaGaleriaH = () => {

    setAtivaBotao(12)
    setPagina(12)

  }
  const paginaGaleriaAlojamento = () => {

    setAtivaBotao(13)
    setPagina(13)

  }
  const paginaGaleriaTR = () => {

    setAtivaBotao(6)
    setPagina(6)

  }

  const paginaForaUP = () => {

    setAtivaBotao(7)
    setPagina(7)

  }


  // A101
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA01 = "A01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA01(filteredResults);
      setPessoasSearchMapA01total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA01).toUpperCase())
  }, [galerias])

  // A02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA02 = "A02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA02(filteredResults);
      setPessoasSearchMapA02total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA02).toUpperCase())
  }, [galerias])

  // A03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA03 = "A03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA03(filteredResults);
      setPessoasSearchMapA03total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA03).toUpperCase())
  }, [galerias])

  // A04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA04 = "A04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA04(filteredResults);
      setPessoasSearchMapA04total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA04).toUpperCase())
  }, [galerias])

  // A05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA05 = "A05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA05(filteredResults);
      setPessoasSearchMapA05total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA05).toUpperCase())
  }, [galerias])

  // A06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA06 = "A06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA06(filteredResults);
      setPessoasSearchMapA06total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA06).toUpperCase())
  }, [galerias])

  // A07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA07 = "A07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA07(filteredResults);
      setPessoasSearchMapA07total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA07).toUpperCase())
  }, [galerias])


  // A08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA08 = "A08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA08(filteredResults);
      setPessoasSearchMapA08total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA08).toUpperCase())
  }, [galerias])
  // A09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA09 = "A09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA09(filteredResults);
      setPessoasSearchMapA09total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA09).toUpperCase())
  }, [galerias])

  // A10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA10 = "A10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA10(filteredResults);
      setPessoasSearchMapA10total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA10).toUpperCase())
  }, [galerias])
  // A11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA11 = "A11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA11(filteredResults);
      setPessoasSearchMapA11total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA11).toUpperCase())
  }, [galerias])

  // A12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA12 = "A12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA12(filteredResults);
      setPessoasSearchMapA12total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA12).toUpperCase())
  }, [galerias])


  // A13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA13 = "A13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA13(filteredResults);
      setPessoasSearchMapA13total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA13).toUpperCase())
  }, [galerias])

  // A14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA14 = "A14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA14(filteredResults);
      setPessoasSearchMapA14total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA14).toUpperCase())
  }, [galerias])

  // A15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA15 = "A15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA15(filteredResults);
      setPessoasSearchMapA15total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA15).toUpperCase())
  }, [galerias])

  // A16
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA16 = "A16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA16(filteredResults);
      setPessoasSearchMapA16total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA16).toUpperCase())
  }, [galerias])


  // A17
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA17 = "A17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA17(filteredResults);
      setPessoasSearchMapA17total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA17).toUpperCase())
  }, [galerias])

  // A18
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA18 = "A18";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA18(filteredResults);
      setPessoasSearchMapA18total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA18).toUpperCase())
  }, [galerias])

  // A19
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA19 = "A19";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA19(filteredResults);
      setPessoasSearchMapA19total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA19).toUpperCase())
  }, [galerias])

  // A20
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA20 = "A20";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA20(filteredResults);
      setPessoasSearchMapA20total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA20).toUpperCase())
  }, [galerias])


  // B01
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB01 = "B01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB01(filteredResults);
      setPessoasSearchMapB01total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB01).toUpperCase())
  }, [galerias])

  // B02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB02 = "B02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB02(filteredResults);
      setPessoasSearchMapB02total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB02).toUpperCase())
  }, [galerias])

  // B03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB03 = "B03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB03(filteredResults);
      setPessoasSearchMapB03total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB03).toUpperCase())
  }, [galerias])

  // B04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB04 = "B04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB04(filteredResults);
      setPessoasSearchMapB04total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB04).toUpperCase())
  }, [galerias])
  // B05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB05 = "B05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB05(filteredResults);
      setPessoasSearchMapB05total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB05).toUpperCase())
  }, [galerias])

  // B06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB06 = "B06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB06(filteredResults);
      setPessoasSearchMapB06total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB06).toUpperCase())
  }, [galerias])

  // B07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB07 = "B07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB07(filteredResults);
      setPessoasSearchMapB07total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB07).toUpperCase())
  }, [galerias])


  // B08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB08 = "B08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB08(filteredResults);
      setPessoasSearchMapB08total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB08).toUpperCase())
  }, [galerias])
  // B09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB09 = "B09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB09(filteredResults);
      setPessoasSearchMapB09total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB09).toUpperCase())
  }, [galerias])

  // B10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB10 = "B10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB10(filteredResults);
      setPessoasSearchMapB10total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB10).toUpperCase())
  }, [galerias])
  // B11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB11 = "B11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB11(filteredResults);
      setPessoasSearchMapB11total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB11).toUpperCase())
  }, [galerias])

  // B12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB12 = "B12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB12(filteredResults);
      setPessoasSearchMapB12total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB12).toUpperCase())
  }, [galerias])


  // B13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB13 = "B13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB13(filteredResults);
      setPessoasSearchMapB13total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB13).toUpperCase())
  }, [galerias])

  // B14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB14 = "B14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB14(filteredResults);
      setPessoasSearchMapB14total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB14).toUpperCase())
  }, [galerias])

  // B15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB15 = "B15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB15(filteredResults);
      setPessoasSearchMapB15total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB15).toUpperCase())
  }, [galerias])

  // B16
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB16 = "B16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB16(filteredResults);
      setPessoasSearchMapB16total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB16).toUpperCase())
  }, [galerias])


  // B17
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB17 = "B17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB17(filteredResults);
      setPessoasSearchMapB17total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB17).toUpperCase())
  }, [galerias])

  // B18
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB18 = "B18";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB18(filteredResults);
      setPessoasSearchMapB18total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB18).toUpperCase())
  }, [galerias])

  // B19
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB19 = "B19";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB19(filteredResults);
      setPessoasSearchMapB19total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB19).toUpperCase())
  }, [galerias])

  // B20
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB20 = "B20";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB20(filteredResults);
      setPessoasSearchMapB20total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB20).toUpperCase())
  }, [galerias])



  //C C01
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC01 = "C01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC01(filteredResults);
      setPessoasSearchMapC01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC01).toUpperCase())
  }, [galerias])

  // C02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC02 = "C02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC02(filteredResults);
      setPessoasSearchMapC02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC02).toUpperCase())
  }, [galerias])

  // C03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC03 = "C03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC03(filteredResults);
      setPessoasSearchMapC03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC03).toUpperCase())
  }, [galerias])

  // C04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC04 = "C04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC04(filteredResults);
      setPessoasSearchMapC04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC04).toUpperCase())
  }, [galerias])
  // C05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC05 = "C05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC05(filteredResults);
      setPessoasSearchMapC05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC05).toUpperCase())
  }, [galerias])

  // C06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC06 = "C06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC06(filteredResults);
      setPessoasSearchMapC06total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC06).toUpperCase())
  }, [galerias])

  // C07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC07 = "C07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC07(filteredResults);
      setPessoasSearchMapC07total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC07).toUpperCase())
  }, [galerias])


  // C08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC08 = "C08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC08(filteredResults);
      setPessoasSearchMapC08total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC08).toUpperCase())
  }, [galerias])
  // C09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC09 = "C09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC09(filteredResults);
      setPessoasSearchMapC09total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC09).toUpperCase())
  }, [galerias])

  // C10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC10 = "C10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC10(filteredResults);
      setPessoasSearchMapC10total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC10).toUpperCase())
  }, [galerias])
  // C11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC11 = "C11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC11(filteredResults);
      setPessoasSearchMapC11total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC11).toUpperCase())
  }, [galerias])

  // C12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC12 = "C12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC12(filteredResults);
      setPessoasSearchMapC12total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC12).toUpperCase())
  }, [galerias])


  // C13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC13 = "C13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC13(filteredResults);
      setPessoasSearchMapC13total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC13).toUpperCase())
  }, [galerias])

  // C14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC14 = "C14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC14(filteredResults);
      setPessoasSearchMapC14total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC14).toUpperCase())
  }, [galerias])

  // C15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC15 = "C15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC15(filteredResults);
      setPessoasSearchMapC15total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC15).toUpperCase())
  }, [galerias])

  // C16
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC16 = "C16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC16(filteredResults);
      setPessoasSearchMapC16total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC16).toUpperCase())
  }, [galerias])


  // C17
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC17 = "C17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC17(filteredResults);
      setPessoasSearchMapC17total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC17).toUpperCase())
  }, [galerias])

  // C18
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC18 = "C18";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC18(filteredResults);
      setPessoasSearchMapC18total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC18).toUpperCase())
  }, [galerias])

  // C19
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC19 = "C19";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC19(filteredResults);
      setPessoasSearchMapC19total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC19).toUpperCase())
  }, [galerias])

  // C20
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC20 = "C20";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC20(filteredResults);
      setPessoasSearchMapC20total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC20).toUpperCase())
  }, [galerias])


  // GALERIA F 

  //C C01
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE01 = "E01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE01(filteredResults);
      setPessoasSearchMapE01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE01).toUpperCase())
  }, [galerias])

  // E02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE02 = "E02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE02(filteredResults);
      setPessoasSearchMapE02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE02).toUpperCase())
  }, [galerias])

  // E03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE03 = "E03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE03(filteredResults);
      setPessoasSearchMapE03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE03).toUpperCase())
  }, [galerias])

  // E04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE04 = "E04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE04(filteredResults);
      setPessoasSearchMapE04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE04).toUpperCase())
  }, [galerias])
  // E05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE05 = "E05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE05(filteredResults);
      setPessoasSearchMapE05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE05).toUpperCase())
  }, [galerias])

  // E06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE06 = "E06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE06(filteredResults);
      setPessoasSearchMapE06total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE06).toUpperCase())
  }, [galerias])

  // E07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE07 = "E07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE07(filteredResults);
      setPessoasSearchMapE07total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE07).toUpperCase())
  }, [galerias])


  // E08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE08 = "E08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE08(filteredResults);
      setPessoasSearchMapE08total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE08).toUpperCase())
  }, [galerias])
  // E09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE09 = "E09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE09(filteredResults);
      setPessoasSearchMapE09total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE09).toUpperCase())
  }, [galerias])

  // C10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE10 = "E10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE10(filteredResults);
      setPessoasSearchMapE10total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE10).toUpperCase())
  }, [galerias])
  // E11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE11 = "E11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE11(filteredResults);
      setPessoasSearchMapE11total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE11).toUpperCase())
  }, [galerias])

  // E12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE12 = "E12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE12(filteredResults);
      setPessoasSearchMapE12total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE12).toUpperCase())
  }, [galerias])


  // E13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE13 = "E13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE13(filteredResults);
      setPessoasSearchMapE13total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE13).toUpperCase())
  }, [galerias])

  // E14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE14 = "E14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE14(filteredResults);
      setPessoasSearchMapE14total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE14).toUpperCase())
  }, [galerias])

  // E15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE15 = "E15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE15(filteredResults);
      setPessoasSearchMapE15total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE15).toUpperCase())
  }, [galerias])

  // E16
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE16 = "E16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE16(filteredResults);
      setPessoasSearchMapE16total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE16).toUpperCase())
  }, [galerias])


  // E17
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE17 = "E17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE17(filteredResults);
      setPessoasSearchMapE17total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE17).toUpperCase())
  }, [galerias])

  // E18
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE18 = "E18";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE18(filteredResults);
      setPessoasSearchMapE18total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE18).toUpperCase())
  }, [galerias])

  // E19
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE19 = "E19";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE19(filteredResults);
      setPessoasSearchMapE19total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE19).toUpperCase())
  }, [galerias])

  // C20
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE20 = "E20";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE20(filteredResults);
      setPessoasSearchMapE20total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE20).toUpperCase())
  }, [galerias])

  // GALERIA F

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF01 = "F01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF01(filteredResults);
      setPessoasSearchMapF01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF01).toUpperCase())
  }, [galerias])

  // F02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF02 = "F02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF02(filteredResults);
      setPessoasSearchMapF02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF02).toUpperCase())
  }, [galerias])

  // F03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF03 = "F03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF03(filteredResults);
      setPessoasSearchMapF03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF03).toUpperCase())
  }, [galerias])

  // F04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF04 = "F04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF04(filteredResults);
      setPessoasSearchMapF04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF04).toUpperCase())
  }, [galerias])
  // F05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF05 = "F05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF05(filteredResults);
      setPessoasSearchMapF05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF05).toUpperCase())
  }, [galerias])

  // F06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF06 = "F06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF06(filteredResults);
      setPessoasSearchMapF06total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF06).toUpperCase())
  }, [galerias])

  // F07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF07 = "F07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF07(filteredResults);
      setPessoasSearchMapF07total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF07).toUpperCase())
  }, [galerias])


  // F08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF08 = "F08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF08(filteredResults);
      setPessoasSearchMapF08total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF08).toUpperCase())
  }, [galerias])
  // F09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF09 = "F09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF09(filteredResults);
      setPessoasSearchMapF09total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF09).toUpperCase())
  }, [galerias])

  // C10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF10 = "F10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF10(filteredResults);
      setPessoasSearchMapF10total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF10).toUpperCase())
  }, [galerias])
  // F11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF11 = "F11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF11(filteredResults);
      setPessoasSearchMapF11total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF11).toUpperCase())
  }, [galerias])

  // F12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF12 = "F12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF12(filteredResults);
      setPessoasSearchMapF12total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF12).toUpperCase())
  }, [galerias])


  // F13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF13 = "F13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF13(filteredResults);
      setPessoasSearchMapF13total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF13).toUpperCase())
  }, [galerias])

  // F14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF14 = "F14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF14(filteredResults);
      setPessoasSearchMapF14total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF14).toUpperCase())
  }, [galerias])

  // F15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF15 = "F15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF15(filteredResults);
      setPessoasSearchMapF15total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF15).toUpperCase())
  }, [galerias])

  // F16
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF16 = "F16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF16(filteredResults);
      setPessoasSearchMapF16total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF16).toUpperCase())
  }, [galerias])


  // F17
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF17 = "F17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF17(filteredResults);
      setPessoasSearchMapF17total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF17).toUpperCase())
  }, [galerias])

  // F18
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF18 = "F18";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF18(filteredResults);
      setPessoasSearchMapF18total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF18).toUpperCase())
  }, [galerias])

  // F19
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF19 = "F19";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF19(filteredResults);
      setPessoasSearchMapF19total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF19).toUpperCase())
  }, [galerias])

  // C20
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF20 = "F20";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF20(filteredResults);
      setPessoasSearchMapF20total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF20).toUpperCase())
  }, [galerias])


  // GALERIA "G"

  //C C01
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG01 = "G01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG01(filteredResults);
      setPessoasSearchMapG01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG01).toUpperCase())
  }, [galerias])

  // G02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG02 = "G02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG02(filteredResults);
      setPessoasSearchMapG02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG02).toUpperCase())
  }, [galerias])

  // G03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG03 = "G03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG03(filteredResults);
      setPessoasSearchMapG03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG03).toUpperCase())
  }, [galerias])

  // G04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG04 = "G04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG04(filteredResults);
      setPessoasSearchMapG04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG04).toUpperCase())
  }, [galerias])
  // G05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG05 = "G05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG05(filteredResults);
      setPessoasSearchMapG05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG05).toUpperCase())
  }, [galerias])

  // G06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG06 = "G06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG06(filteredResults);
      setPessoasSearchMapG06total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG06).toUpperCase())
  }, [galerias])

  // G07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG07 = "G07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG07(filteredResults);
      setPessoasSearchMapG07total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG07).toUpperCase())
  }, [galerias])


  // G08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG08 = "G08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG08(filteredResults);
      setPessoasSearchMapG08total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG08).toUpperCase())
  }, [galerias])
  // G09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG09 = "G09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG09(filteredResults);
      setPessoasSearchMapG09total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG09).toUpperCase())
  }, [galerias])

  // C10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG10 = "G10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG10(filteredResults);
      setPessoasSearchMapG10total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG10).toUpperCase())
  }, [galerias])
  // G11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG11 = "G11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG11(filteredResults);
      setPessoasSearchMapG11total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG11).toUpperCase())
  }, [galerias])

  // G12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG12 = "G12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG12(filteredResults);
      setPessoasSearchMapG12total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG12).toUpperCase())
  }, [galerias])


  // G13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG13 = "G13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG13(filteredResults);
      setPessoasSearchMapG13total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG13).toUpperCase())
  }, [galerias])

  // G14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG14 = "G14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG14(filteredResults);
      setPessoasSearchMapG14total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG14).toUpperCase())
  }, [galerias])

  // G15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG15 = "G15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG15(filteredResults);
      setPessoasSearchMapG15total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG15).toUpperCase())
  }, [galerias])

  // G16
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG16 = "G16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG16(filteredResults);
      setPessoasSearchMapG16total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG16).toUpperCase())
  }, [galerias])


  // G17
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG17 = "G17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG17(filteredResults);
      setPessoasSearchMapG17total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG17).toUpperCase())
  }, [galerias])

  // G18
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG18 = "G18";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG18(filteredResults);
      setPessoasSearchMapG18total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG18).toUpperCase())
  }, [galerias])

  // G19
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG19 = "G19";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG19(filteredResults);
      setPessoasSearchMapG19total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG19).toUpperCase())
  }, [galerias])

  // C20
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaG20 = "G20";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapG20(filteredResults);
      setPessoasSearchMapG20total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
    performSearch((celaG20).toUpperCase())
  }, [galerias])

  // galeria H

  // D201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaHD01 = "HD01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapHD01(filteredResults);
      setPessoasSearchMapHD01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaHD01).toUpperCase())
  }, [galerias])

  // HD02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaHD02 = "HD02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapHD02(filteredResults);
      setPessoasSearchMapHD02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaHD02).toUpperCase())
  }, [galerias])

  // HD03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaHD03 = "HD03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapHD03(filteredResults);
      setPessoasSearchMapHD03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaHD03).toUpperCase())
  }, [galerias])

  // HD04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaHD04 = "HD04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapHD04(filteredResults);
      setPessoasSearchMapHD04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaHD04).toUpperCase())
  }, [galerias])
  // HD05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaHD05 = "HD05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapHD05(filteredResults);
      setPessoasSearchMapHD05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaHD05).toUpperCase())
  }, [galerias])

  // D201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaHE01 = "HEX01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapHE01(filteredResults);
      setPessoasSearchMapHE01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaHE01).toUpperCase())
  }, [galerias])

  // HE02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaHE02 = "HEX02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapHE02(filteredResults);
      setPessoasSearchMapHE02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaHE02).toUpperCase())
  }, [galerias])

  // HE03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaHE03 = "HEX03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapHE03(filteredResults);
      setPessoasSearchMapHE03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaHE03).toUpperCase())
  }, [galerias])

  // HE04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaHE04 = "HEX04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapHE04(filteredResults);
      setPessoasSearchMapHE04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaHE04).toUpperCase())
  }, [galerias])
  // HE05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaHE05 = "HEX05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapHE05(filteredResults);
      setPessoasSearchMapHE05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaHE05).toUpperCase())
  }, [galerias])





  // D201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaDD01 = "DD01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapDD01(filteredResults);
      setPessoasSearchMapDD01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaDD01).toUpperCase())
  }, [galerias])

  // DD02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaDD02 = "DD02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapDD02(filteredResults);
      setPessoasSearchMapDD02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaDD02).toUpperCase())
  }, [galerias])

  // DD03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaDD03 = "DD03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapDD03(filteredResults);
      setPessoasSearchMapDD03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaDD03).toUpperCase())
  }, [galerias])

  // DD04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaDD04 = "DD04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapDD04(filteredResults);
      setPessoasSearchMapDD04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaDD04).toUpperCase())
  }, [galerias])
  // DD05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaDD05 = "DD05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapDD05(filteredResults);
      setPessoasSearchMapDD05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaDD05).toUpperCase())
  }, [galerias])

  // D201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaDE01 = "DEX01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapDE01(filteredResults);
      setPessoasSearchMapDE01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaDE01).toUpperCase())
  }, [galerias])

  // DE02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaDE02 = "DEX02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapDE02(filteredResults);
      setPessoasSearchMapDE02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaDE02).toUpperCase())
  }, [galerias])

  // DE03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaDE03 = "DEX03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapDE03(filteredResults);
      setPessoasSearchMapDE03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaDE03).toUpperCase())
  }, [galerias])

  // DE04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaDE04 = "DEX04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapDE04(filteredResults);
      setPessoasSearchMapDE04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaDE04).toUpperCase())
  }, [galerias])
  // DE05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaDE05 = "DEX05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapDE05(filteredResults);
      setPessoasSearchMapDE05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaDE05).toUpperCase())
  }, [galerias])


  // TRIAGEM 
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR = "TR00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR(filteredResults);
      setPessoasSearchMapTRtotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR).toUpperCase())
  }, [galerias])

  // TR201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR01 = "TR01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR01(filteredResults);
      setPessoasSearchMapTR01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR01).toUpperCase())
  }, [galerias])


  // TR02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR02 = "TR02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR02(filteredResults);
      setPessoasSearchMapTR02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR02).toUpperCase())
  }, [galerias])



  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR03 = "TR03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR03(filteredResults);
      setPessoasSearchMapTR03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR03).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR04 = "TR04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR04(filteredResults);
      setPessoasSearchMapTR04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR04).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR05 = "TR05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR05(filteredResults);
      setPessoasSearchMapTR05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR05).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR06 = "TR06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR06(filteredResults);
      setPessoasSearchMapTR06total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR06).toUpperCase())
  }, [galerias])


  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let alojamento = "ALOJAMENTO";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapALOJAMENTO(filteredResults);
      setPessoasSearchMapALOJAMENTOtotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((alojamento).toUpperCase())
  }, [galerias])



  // DOMICILIAR
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let domiciliar = "DOMICILIAR";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasDomiciliar(filteredResults);
      setPessoasDomiciliartotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((domiciliar).toUpperCase())
  }, [galerias])


  // HOSPITAL
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let hospital = "HOSPITAL";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasHospital(filteredResults);
      setPessoasHospitaltotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((hospital).toUpperCase())
  }, [galerias])


  // HOSPITAL
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let pernoite = "PERNOITE";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasPernoite(filteredResults);
      setPessoasPernoitetotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((pernoite).toUpperCase())
  }, [galerias])





  return (<>
    {loading && <Loading></Loading>}
    <div className={styles.divtabs}>


      <Modal isOpen={modalBuscaAberto} toggle={toggleModalBusca} style={{ maxWidth: '1300px', width: '100%' }}>
        <ModalBody>
          <div style={{ marginTop: 15, fontFamily: 'policiapenal' }}><center> <p style={{ fontFamily: 'policiapenal', padding: 10, }}><img src={logo} alt="Logo" height={80} style={{ marginBottom: 10 }} /><h2>POLICIA PENAL | ES</h2><h5><b>BUSCA AVANÇADA</b></h5> </p> </center></div>

          <center>

            <input
              type="text"
              autoFocus
              value={termoBusca}
              onChange={(e) => setTermoBusca(e.target.value.toLocaleUpperCase())}
              placeholder="Nome, Infopen, Data de Entrada, Cela, Artigo ..."
              ref={inputBuscaRef}
              style={{
                padding: '10px',
                fontSize: '20px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                width: '80%',
                boxSizing: 'border-box',
                outline: 'none',
                transition: 'box-shadow 0.3s ease',
                marginBottom: 60,
                marginTop: 30

              }}
            />
            {resultadosBusca.length > 0 && <h5>Registros Encontrados: ({resultadosBusca.length})</h5>}  </center>
          <br></br>
          {resultadosBusca.length > 0 && <thead >

            <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd', borderRadius: '15px' }}>
              <tr style={{ backgroundColor: "#253221", color: '#8a9175', borderRadius: '15px', fontWeight: 'lighter' }}>
                <th style={{ width: '200px', textAlign: 'center', padding: '10px' }}></th>
                <th style={{ width: '380px', textAlign: 'center', padding: '10px' }}>Nome</th>
                <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}>Cela</th>
                <th style={{ width: '120px', textAlign: 'center', padding: '10px' }}>Entrada</th>
                <th style={{ width: '120px', textAlign: 'center', padding: '10px' }}>Infopen</th>
                <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}></th>
                {userVerificacao.includes("chefeseg") &&  <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}>Info. Seg.</th>} 
               {userVerificacao.includes("diretor") &&  <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}>Info. Seg.</th>} 
                <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}>Artigo</th>
              </tr>
            </table></thead>}

          {resultadosBusca.map((resultado, index) => (
            <div key={index}>
              <table onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}

                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  border: '1px solid #ddd',
                  borderRadius: '15px',
                  cursor: 'pointer'
                }}>

                <tbody>

                  <tr onClick={() => handleCliqueBuscaCela(resultado.cela, resultado.nome)} style={{
                    backgroundColor: hoveredIndex === index ? '#b8d5c1' : '#c3c8cd',
                    transition: 'background-color 0.3s ease', marginTop: 40
                  }}>

                    <td style={{ width: '50px', textAlign: 'center' }}>
                      <img
                        alt={resultado.nome}
                        width='auto'
                        height={75}
                        src={resultado.imgURL ? resultado.imgURL : userDef}
                        style={{ width: '80px', cursor: 'pointer', maxWidth: '80px', maxHeight: '80px' }}
                      />
                    </td>
                    <td style={{ width: '350px', textAlign: 'center', padding: '10px' }}><b>{resultado.nome}</b></td>
                    <td style={{ width: '80px', textAlign: 'center', padding: '10px' }}><b>{resultado.cela}</b></td>
                    <td style={{ width: '80px', textAlign: 'center', padding: '10px', fontSize: 14 }}>{resultado.selectDate}</td>
                    <td style={{ width: '100px', textAlign: 'right', padding: '10px' }}>
                      <a target="_blank" rel="noreferrer"
                        style={{
                          textDecoration: 'none',
                          color: 'black', fontSize: 14
                        }}
                        href={'https://infopen.sejus.es.gov.br/Identificacao/Presos.aspx?ID=' + resultado.infopen}>
                        {resultado.infopen} <FaEye />
                      </a>
                    </td>
                    {userVerificacao.includes("chefeseg") && <td style={{ width: '100px', textAlign: 'center', padding: '10px' }}> {resultado.visita}</td>}
                    {userVerificacao.includes("diretor") && <td style={{ width: '100px', textAlign: 'center', padding: '10px' }}> {resultado.visita}</td>}
                    <td style={{ width: '100px', textAlign: 'center', padding: '10px' }}>
                      <a target="_blank" rel="noreferrer"
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          fontSize: 14
                        }} >

                      </a>
                    </td>
                    <td style={{ width: '100px', textAlign: 'center', padding: '10px', fontSize: 12 }}>{resultado.artigo}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}

        </ModalBody>

        <center> <button onClick={toggleModalBusca} style={{ backgroundColor: 'gray', color: 'white', fontFamily: 'policiapenal', fontSize: 14, padding: 14, marginBottom: 30, borderRadius: 10, border: 1 }}>FECHAR</button></center>
      </Modal>


      <center>
        <div className={styles.containerSelect} style={{ marginTop: 10, marginBottom: 30 }}>
          <select className={styles.selectTablet} style={{ height: 40, fontSize: 15, }} onChange={(event) => {
            const selectedValue = event.target.value;
            switch (selectedValue) {
              case 'galeriaA':
                paginaGaleriaA();
                break;
              case 'galeriaB':
                paginaGaleriaB();
                break;
              case 'galeriaC':
                paginaGaleriaC();
                break;
              case 'galeriaD':
                paginaGaleriaD();
                break;
              case 'galeriaTR':
                paginaGaleriaTR();
                break;
              case 'foraUP':
                paginaForaUP();
                break;
              case 'totalGeral':
                paginaTotal();
                break;
              default:
                break;
            }
          }}>
            <option value="galeriaA" selected={ativaBotao === 2}>GALERIA (A)</option>
            <option value="galeriaB" selected={ativaBotao === 3}>GALERIA (B)</option>
            <option value="galeriaC" selected={ativaBotao === 4}>GALERIA (C)</option>
            <option value="galeriaD" selected={ativaBotao === 5}>GALERIA (D)</option>
            <option value="galeriaTR" selected={ativaBotao === 6}>TRIAGEM (TR)</option>
            <option value="foraUP" selected={ativaBotao === 7}>FORA DA UNIDADE</option>
            <option value="totalGeral" selected={ativaBotao === 1}>QUANTITATIVO</option>
          </select>

        </div>
      </center>

      <div id="tab1" onClick={paginaGaleriaA}  >
        <p className={styles.dataColumnTopo} style={ativaBotao === 2 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }} >GALERIA (A)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab2" onClick={paginaGaleriaB} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 3 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (B)</p>

      </div>

      &nbsp; &nbsp; &nbsp;

      <div id="tab3" onClick={paginaGaleriaC} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 4 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (C)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaD} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 5 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (D)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaE} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 9 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (E)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaF} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 10 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (F)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaG} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 11 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (G)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaH} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 12 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (H)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaAlojamento} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 13 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>ALOJAMENTO</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab5" onClick={paginaGaleriaTR} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 6 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>TRIAGEM (TR)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab8" onClick={paginaForaUP}>
        <p className={styles.dataColumnTopo} style={ativaBotao === 7 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>FORA DA UP</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab6" onClick={paginaTotal}>
        <p className={styles.dataColumnTopo} style={ativaBotao === 1 ? { color: "green", cursor: 'pointer', paddingTop: 14, marginRight: 20 } : { cursor: 'pointer', paddingTop: 14, marginRight: 20 }}>TOTAL</p>
      </div>
      <div onClick={toggleModalBusca} style={{ cursor: 'pointer' }} id="tab10" >
        <button className={styles.dataColumnTopo} style={{ backgroundColor: '#545f34', borderRadius: 5, color: '#b8c495', lineHeight: 2.5, display: 'flex', flexDirection: 'row' }} >BUSCAR &nbsp;<FaSearch style={{ marginTop: 12 }} /></button>
      </div>

    </div>



    {pagina === 1 && <div style={{ fontFamily: 'policiapenal' }}> <center> <h1><strong>CONTAGEM GERAL</strong></h1><p>TOTALIZADORES POR GALERIAS E VAGAS</p><br></br></center>
      <div>
        <center><div className={styles.quadroFora}>
          <Table dark>
            <tbody style={{ boxShadow: '2px 3px 1px #888888', lineHeight: '25px' }} >
              <tr style={{ height: 42 }}>
                <th style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }} scope="row"><center>LOCAL</center>
                  <center><b></b></center>

                </th>
                <th style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }}>PRESOS

                </th>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }}>VAGAS

                </td>

                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }}>VAGAS %

                </td>
              </tr>
              <tr>
                <th style={{ color: "black", fontSize: "18px", letterSpacing: "0.5px", verticalAlign: "middle", justifyItems: "right", textAlign: "right", backgroundColor: "#959595", fontFamily: "policiapenal", fontWeight: 'bold' }} scope="row">
                  <p><center> &nbsp;&nbsp;&nbsp;GALERIA A </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA B </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA C </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA D </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA E </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA F </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA G </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA H </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;ALOJAMENTO </center> </p>
                  <p> <center>&nbsp;&nbsp;&nbsp;TRIAGEM</center> </p>
                </th>
                <td style={{ fontSize: "17px", backgroundColor: "#959595", letterSpacing: "2px", fontWeight: "bold", verticalAlign: "middle", color: "black", paddingLeft: "15px" }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaA}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaB}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaC}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaD}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaE}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaF}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaG}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaH}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{pessoasSearchMapALOJAMENTOtotal}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaTR}</p>)}
                </td>
                <td className={styles.dataColumnTopo} style={{ verticalAlign: "middle", fontSize: "17px", letterSpacing: "2px", color: "black", backgroundColor: "#959595" }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{80 - totalSomaA}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{80 - totalSomaB}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{80 - totalSomaC}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{20 - totalSomaD}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{80 - totalSomaE}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{80 - totalSomaF}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{80 - totalSomaG}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{20 - totalSomaH}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{10 - pessoasSearchMapALOJAMENTOtotal}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{12 - totalSomaTR}</p>)}
                </td>
                <td className={styles.dataColumnTopo} style={{ verticalAlign: "middle", fontSize: "17px", letterSpacing: "2px", color: "black", backgroundColor: "#959595" }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((80 - totalSomaA) * 100) / 542).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((80 - totalSomaB) * 100) / 542).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((80 - totalSomaC) * 100) / 542).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((20 - totalSomaD) * 100) / 542).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((80 - totalSomaE) * 100) / 542).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((80 - totalSomaF) * 100) / 542).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((80 - totalSomaG) * 100) / 542).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((20 - totalSomaH) * 100) / 542).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((40 - pessoasSearchMapALOJAMENTOtotal) * 100) / 542).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((12 - totalSomaTR) * 100) / 542).toFixed(1)}%</p>)}

                </td>

              </tr>
              <tr >
                <th style={{ backgroundColor: "#253221", verticalAlign: "middle", color: '#8a9175' }} scope="row">
                  <center><b>TOTAL NA UNIDADE</b></center>

                </th>
                <td style={{ fontSize: "17px", backgroundColor: "#253221", verticalAlign: "middle", color: '#8a9175', letterSpacing: "1px" }}>
                  {!isSkeletonLoading && <strong>{totalUp}</strong>}
                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", color: '#8a9175', fontSize: "17px", letterSpacing: "1px" }}>
                  {!isSkeletonLoading && <strong> {

                    80 - totalSomaA +
                    80 - totalSomaB +
                    80 - totalSomaC +
                    80 - totalSomaE +
                    80 - totalSomaF +
                    80 - totalSomaG +
                    20 - totalSomaD +
                    20 - totalSomaH +
                    10 - pessoasSearchMapALOJAMENTOtotal +
                    12 - totalSomaTR}</strong>}
                </td>
                <td className={styles.dataColumnTopo} style={{ letterSpacing: "1px", fontSize: "17px", verticalAlign: "middle", fontWeight: 'bolder', backgroundColor: "#253221", color: '#8a9175' }}>
                  {!isSkeletonLoading && <strong>   {(
                    parseFloat((((80 - totalSomaA) * 100 / 542).toFixed(1))) +
                    parseFloat((((80 - totalSomaB) * 100 / 542).toFixed(1))) +
                    parseFloat((((80 - totalSomaC) * 100 / 542).toFixed(1))) +
                    parseFloat((((80 - totalSomaF) * 100 / 542).toFixed(1))) +
                    parseFloat((((80 - totalSomaG) * 100 / 542).toFixed(1))) +
                    parseFloat((((80 - totalSomaE) * 100 / 542).toFixed(1))) +
                    parseFloat((((20 - totalSomaD) * 100 / 542).toFixed(1))) +
                    parseFloat((((20 - totalSomaH) * 100 / 542).toFixed(1))) +
                    parseFloat((((12 - totalSomaTR) * 100 / 542).toFixed(1)))
                  ).toFixed(2)} % </strong>}


                </td>


              </tr>
              <tr >
                <th scope="row" style={{ backgroundColor: "#959595", color: 'black' }}>
                  <p style={{ verticalAlign: "middle", fontSize: "18px", letterSpacing: "2px" }}> <center>HOSPITAL</center></p>
                  <p style={{ verticalAlign: "middle", fontSize: "18px", letterSpacing: "2px" }}> <center>PERNOITE</center></p>
                  <p style={{ verticalAlign: "middle", fontSize: "18px", letterSpacing: "2px" }}> <center>DOMICILIAR</center></p>
                </th>
                <td style={{ backgroundColor: "#959595", color: 'black' }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p style={{ fontSize: "17px", letterSpacing: "2px", verticalAlign: "middle", fontWeight: "bold", color: 'black' }}>{pessoasHospitaltotal}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p style={{ fontSize: "17px", letterSpacing: "2px", verticalAlign: "middle", fontWeight: "bold", color: 'black' }}>{pessoasPernoitetotal}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p style={{ fontSize: "17px", letterSpacing: "2px", verticalAlign: "middle", fontWeight: "bold", color: 'black' }}>{pessoasDomiciliartotal}</p>)}
                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#959595" }}>

                </td >
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#959595" }}>

                </td>

              </tr>
              <tr>
                <th style={{ borderBottom: "none", backgroundColor: "#253221", verticalAlign: "middle", color: '#8a9175' }} scope="row">
                  <center><b>TOTAL GERAL</b></center>
                </th>
                <td style={{ borderBottom: "none", fontSize: "17px", backgroundColor: "#253221", verticalAlign: "middle", color: "#8a9175", letterSpacing: "1px" }}>
                  {!isSkeletonLoading && <strong>{totalGeral}</strong>}
                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", borderBottom: "none" }}>

                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", borderBottom: "none" }}>
                </td>
              </tr>
            </tbody>
          </Table>
        </div></center>
        <br />
      </div>

    </div>}

    <div id="someLista">
      <div className={styles.home}>
        <br></br>
        <br></br>

        {pagina === 2 && <>
          <h1 ><strong>GALERIA "A"</strong>({totalSomaA})</h1>

          <div id="A01"></div><Cela cela="A01" usuario={usuario} celaTotal={pessoasSearchMapA01total} celaMap={(pessoasSearchMapA01 && pessoasSearchMapA01)} nomeBuscado={nomeBuscado} />
          <div id="A02"></div><Cela cela="A02" usuario={usuario} celaTotal={pessoasSearchMapA02total} celaMap={(pessoasSearchMapA02 && pessoasSearchMapA02)} nomeBuscado={nomeBuscado} />
          <div id="A03"></div><Cela cela="A03" usuario={usuario} celaTotal={pessoasSearchMapA03total} celaMap={(pessoasSearchMapA03 && pessoasSearchMapA03)} nomeBuscado={nomeBuscado} />
          <div id="A04"></div><Cela cela="A04" usuario={usuario} celaTotal={pessoasSearchMapA04total} celaMap={(pessoasSearchMapA04 && pessoasSearchMapA04)} nomeBuscado={nomeBuscado} />
          <div id="A05"></div><Cela cela="A05" usuario={usuario} celaTotal={pessoasSearchMapA05total} celaMap={(pessoasSearchMapA05 && pessoasSearchMapA05)} nomeBuscado={nomeBuscado} />
          <div id="A06"></div><Cela cela="A06" usuario={usuario} celaTotal={pessoasSearchMapA06total} celaMap={(pessoasSearchMapA06 && pessoasSearchMapA06)} nomeBuscado={nomeBuscado} />
          <div id="A07"></div><Cela cela="A07" usuario={usuario} celaTotal={pessoasSearchMapA07total} celaMap={(pessoasSearchMapA07 && pessoasSearchMapA07)} nomeBuscado={nomeBuscado} />
          <div id="A08"></div><Cela cela="A08" usuario={usuario} celaTotal={pessoasSearchMapA08total} celaMap={(pessoasSearchMapA08 && pessoasSearchMapA08)} nomeBuscado={nomeBuscado} />
          <div id="A09"></div><Cela cela="A09" usuario={usuario} celaTotal={pessoasSearchMapA09total} celaMap={(pessoasSearchMapA09 && pessoasSearchMapA09)} nomeBuscado={nomeBuscado} />
          <div id="A10"></div><Cela cela="A10" usuario={usuario} celaTotal={pessoasSearchMapA10total} celaMap={(pessoasSearchMapA10 && pessoasSearchMapA10)} nomeBuscado={nomeBuscado} />
          <div id="A11"></div><Cela cela="A11" usuario={usuario} celaTotal={pessoasSearchMapA11total} celaMap={(pessoasSearchMapA11 && pessoasSearchMapA11)} nomeBuscado={nomeBuscado} />
          <div id="A12"></div><Cela cela="A12" usuario={usuario} celaTotal={pessoasSearchMapA12total} celaMap={(pessoasSearchMapA12 && pessoasSearchMapA12)} nomeBuscado={nomeBuscado} />
          <div id="A13"></div> <Cela cela="A13" usuario={usuario} celaTotal={pessoasSearchMapA13total} celaMap={(pessoasSearchMapA13 && pessoasSearchMapA13)} nomeBuscado={nomeBuscado} />
          <div id="A14"></div> <Cela cela="A14" usuario={usuario} celaTotal={pessoasSearchMapA14total} celaMap={(pessoasSearchMapA14 && pessoasSearchMapA14)} nomeBuscado={nomeBuscado} />
          <div id="A15"></div><Cela cela="A15" usuario={usuario} celaTotal={pessoasSearchMapA15total} celaMap={(pessoasSearchMapA15 && pessoasSearchMapA15)} nomeBuscado={nomeBuscado} />
          <div id="A16"></div> <Cela cela="A16" usuario={usuario} celaTotal={pessoasSearchMapA16total} celaMap={(pessoasSearchMapA16 && pessoasSearchMapA16)} nomeBuscado={nomeBuscado} />
          <div id="A17"></div> <Cela cela="A17" usuario={usuario} celaTotal={pessoasSearchMapA17total} celaMap={(pessoasSearchMapA17 && pessoasSearchMapA17)} nomeBuscado={nomeBuscado} />
          <div id="A18"></div> <Cela cela="A18" usuario={usuario} celaTotal={pessoasSearchMapA18total} celaMap={(pessoasSearchMapA18 && pessoasSearchMapA18)} nomeBuscado={nomeBuscado} />
          <div id="A19"></div><Cela cela="A19" usuario={usuario} celaTotal={pessoasSearchMapA19total} celaMap={(pessoasSearchMapA19 && pessoasSearchMapA19)} nomeBuscado={nomeBuscado} />
          <div id="A20"></div><Cela cela="A20" usuario={usuario} celaTotal={pessoasSearchMapA20total} celaMap={(pessoasSearchMapA20 && pessoasSearchMapA20)} nomeBuscado={nomeBuscado} />

          <br></br><br></br>

        </>}

        {pagina === 3 && <><h1 ><strong>GALERIA "B"</strong>({totalSomaB})</h1>

          <div id="B01"></div> <Cela cela="B01" usuario={usuario} celaTotal={pessoasSearchMapB01total} celaMap={(pessoasSearchMapB01 && pessoasSearchMapB01)} nomeBuscado={nomeBuscado} />
          <div id="B02"></div><Cela cela="B02" usuario={usuario} celaTotal={pessoasSearchMapB02total} celaMap={(pessoasSearchMapB02 && pessoasSearchMapB02)} nomeBuscado={nomeBuscado} />
          <div id="B03"></div><Cela cela="B03" usuario={usuario} celaTotal={pessoasSearchMapB03total} celaMap={(pessoasSearchMapB03 && pessoasSearchMapB03)} nomeBuscado={nomeBuscado} />
          <div id="B04"></div><Cela cela="B04" usuario={usuario} celaTotal={pessoasSearchMapB04total} celaMap={(pessoasSearchMapB04 && pessoasSearchMapB04)} nomeBuscado={nomeBuscado} />
          <div id="B05"></div><Cela cela="B05" usuario={usuario} celaTotal={pessoasSearchMapB05total} celaMap={(pessoasSearchMapB05 && pessoasSearchMapB05)} nomeBuscado={nomeBuscado} />
          <div id="B06"></div><Cela cela="B06" usuario={usuario} celaTotal={pessoasSearchMapB06total} celaMap={(pessoasSearchMapB06 && pessoasSearchMapB06)} nomeBuscado={nomeBuscado} />
          <div id="B07"></div><Cela cela="B07" usuario={usuario} celaTotal={pessoasSearchMapB07total} celaMap={(pessoasSearchMapB07 && pessoasSearchMapB07)} nomeBuscado={nomeBuscado} />
          <div id="B08"></div><Cela cela="B08" usuario={usuario} celaTotal={pessoasSearchMapB08total} celaMap={(pessoasSearchMapB08 && pessoasSearchMapB08)} nomeBuscado={nomeBuscado} />
          <div id="B09"></div> <Cela cela="B09" usuario={usuario} celaTotal={pessoasSearchMapB09total} celaMap={(pessoasSearchMapB09 && pessoasSearchMapB09)} nomeBuscado={nomeBuscado} />
          <div id="B10"></div><Cela cela="B10" usuario={usuario} celaTotal={pessoasSearchMapB10total} celaMap={(pessoasSearchMapB10 && pessoasSearchMapB10)} nomeBuscado={nomeBuscado} />
          <div id="B11"></div><Cela cela="B11" usuario={usuario} celaTotal={pessoasSearchMapB11total} celaMap={(pessoasSearchMapB11 && pessoasSearchMapB11)} nomeBuscado={nomeBuscado} />
          <div id="B12"></div><Cela cela="B12" usuario={usuario} celaTotal={pessoasSearchMapB12total} celaMap={(pessoasSearchMapB12 && pessoasSearchMapB12)} nomeBuscado={nomeBuscado} />
          <div id="B13"></div><Cela cela="B13" usuario={usuario} celaTotal={pessoasSearchMapB13total} celaMap={(pessoasSearchMapB13 && pessoasSearchMapB13)} nomeBuscado={nomeBuscado} />
          <div id="B14"></div><Cela cela="B14" usuario={usuario} celaTotal={pessoasSearchMapB14total} celaMap={(pessoasSearchMapB14 && pessoasSearchMapB14)} nomeBuscado={nomeBuscado} />
          <div id="B15"></div><Cela cela="B15" usuario={usuario} celaTotal={pessoasSearchMapB15total} celaMap={(pessoasSearchMapB15 && pessoasSearchMapB15)} nomeBuscado={nomeBuscado} />
          <div id="B16"></div><Cela cela="B16" usuario={usuario} celaTotal={pessoasSearchMapB16total} celaMap={(pessoasSearchMapB16 && pessoasSearchMapB16)} nomeBuscado={nomeBuscado} />
          <div id="B17"></div> <Cela cela="B17" usuario={usuario} celaTotal={pessoasSearchMapB17total} celaMap={(pessoasSearchMapB17 && pessoasSearchMapB17)} nomeBuscado={nomeBuscado} />
          <div id="B18"></div> <Cela cela="B18" usuario={usuario} celaTotal={pessoasSearchMapB18total} celaMap={(pessoasSearchMapB18 && pessoasSearchMapB18)} nomeBuscado={nomeBuscado} />
          <div id="B19"></div><Cela cela="B19" usuario={usuario} celaTotal={pessoasSearchMapB19total} celaMap={(pessoasSearchMapB19 && pessoasSearchMapB19)} nomeBuscado={nomeBuscado} />
          <div id="B20"></div> <Cela cela="B20" usuario={usuario} celaTotal={pessoasSearchMapB20total} celaMap={(pessoasSearchMapB20 && pessoasSearchMapB20)} nomeBuscado={nomeBuscado} />
        </>}


        {pagina === 4 && <>
          <h1><strong>GALERIA "C"</strong>({totalSomaC})</h1>

          <div id="C01"></div><Cela cela="C01" usuario={usuario} celaTotal={pessoasSearchMapC01total} celaMap={(pessoasSearchMapC01 && pessoasSearchMapC01)} nomeBuscado={nomeBuscado} />
          <div id="C02"></div><Cela cela="C02" usuario={usuario} celaTotal={pessoasSearchMapC02total} celaMap={(pessoasSearchMapC02 && pessoasSearchMapC02)} nomeBuscado={nomeBuscado} />
          <div id="C03"></div><Cela cela="C03" usuario={usuario} celaTotal={pessoasSearchMapC03total} celaMap={(pessoasSearchMapC03 && pessoasSearchMapC03)} nomeBuscado={nomeBuscado} />
          <div id="C04"></div><Cela cela="C04" usuario={usuario} celaTotal={pessoasSearchMapC04total} celaMap={(pessoasSearchMapC04 && pessoasSearchMapC04)} nomeBuscado={nomeBuscado} />
          <div id="C05"></div><Cela cela="C05" usuario={usuario} celaTotal={pessoasSearchMapC05total} celaMap={(pessoasSearchMapC05 && pessoasSearchMapC05)} nomeBuscado={nomeBuscado} />
          <div id="C06"></div><Cela cela="C06" usuario={usuario} celaTotal={pessoasSearchMapC06total} celaMap={(pessoasSearchMapC06 && pessoasSearchMapC06)} nomeBuscado={nomeBuscado} />
          <div id="C07"></div><Cela cela="C07" usuario={usuario} celaTotal={pessoasSearchMapC07total} celaMap={(pessoasSearchMapC07 && pessoasSearchMapC07)} nomeBuscado={nomeBuscado} />
          <div id="C08"></div><Cela cela="C08" usuario={usuario} celaTotal={pessoasSearchMapC08total} celaMap={(pessoasSearchMapC08 && pessoasSearchMapC08)} nomeBuscado={nomeBuscado} />
          <div id="C09"></div><Cela cela="C09" usuario={usuario} celaTotal={pessoasSearchMapC09total} celaMap={(pessoasSearchMapC09 && pessoasSearchMapC09)} nomeBuscado={nomeBuscado} />
          <div id="C10"></div><Cela cela="C10" usuario={usuario} celaTotal={pessoasSearchMapC10total} celaMap={(pessoasSearchMapC10 && pessoasSearchMapC10)} nomeBuscado={nomeBuscado} />
          <div id="C11"></div><Cela cela="C11" usuario={usuario} celaTotal={pessoasSearchMapC11total} celaMap={(pessoasSearchMapC11 && pessoasSearchMapC11)} nomeBuscado={nomeBuscado} />
          <div id="C12"></div><Cela cela="C12" usuario={usuario} celaTotal={pessoasSearchMapC12total} celaMap={(pessoasSearchMapC12 && pessoasSearchMapC12)} nomeBuscado={nomeBuscado} />
          <div id="C13"></div><Cela cela="C13" usuario={usuario} celaTotal={pessoasSearchMapC13total} celaMap={(pessoasSearchMapC13 && pessoasSearchMapC13)} nomeBuscado={nomeBuscado} />
          <div id="C14"></div><Cela cela="C14" usuario={usuario} celaTotal={pessoasSearchMapC14total} celaMap={(pessoasSearchMapC14 && pessoasSearchMapC14)} nomeBuscado={nomeBuscado} />
          <div id="C15"></div><Cela cela="C15" usuario={usuario} celaTotal={pessoasSearchMapC15total} celaMap={(pessoasSearchMapC15 && pessoasSearchMapC15)} nomeBuscado={nomeBuscado} />
          <div id="C16"></div><Cela cela="C16" usuario={usuario} celaTotal={pessoasSearchMapC16total} celaMap={(pessoasSearchMapC16 && pessoasSearchMapC16)} nomeBuscado={nomeBuscado} />
          <div id="C17"></div><Cela cela="C17" usuario={usuario} celaTotal={pessoasSearchMapC17total} celaMap={(pessoasSearchMapC17 && pessoasSearchMapC17)} nomeBuscado={nomeBuscado} />
          <div id="C18"></div><Cela cela="C18" usuario={usuario} celaTotal={pessoasSearchMapC18total} celaMap={(pessoasSearchMapC18 && pessoasSearchMapC18)} nomeBuscado={nomeBuscado} />
          <div id="C19"></div><Cela cela="C19" usuario={usuario} celaTotal={pessoasSearchMapC19total} celaMap={(pessoasSearchMapC19 && pessoasSearchMapC19)} nomeBuscado={nomeBuscado} />
          <div id="C20"></div><Cela cela="C20" usuario={usuario} celaTotal={pessoasSearchMapC20total} celaMap={(pessoasSearchMapC20 && pessoasSearchMapC20)} nomeBuscado={nomeBuscado} />
        </>}
        {pagina === 5 && <>
          <h1 ><strong>GALERIA "D"</strong>({totalSomaD})</h1>

          <div id="DD01"></div><Cela cela="DD01" usuario={usuario} celaTotal={pessoasSearchMapDD01total} celaMap={(pessoasSearchMapDD01 && pessoasSearchMapDD01)} nomeBuscado={nomeBuscado} />
          <div id="DD02"></div><Cela cela="DD02" usuario={usuario} celaTotal={pessoasSearchMapDD02total} celaMap={(pessoasSearchMapDD02 && pessoasSearchMapDD02)} nomeBuscado={nomeBuscado} />
          <div id="DD03"></div><Cela cela="DD03" usuario={usuario} celaTotal={pessoasSearchMapDD03total} celaMap={(pessoasSearchMapDD03 && pessoasSearchMapDD03)} nomeBuscado={nomeBuscado} />
          <div id="DD04"></div><Cela cela="DD04" usuario={usuario} celaTotal={pessoasSearchMapDD04total} celaMap={(pessoasSearchMapDD04 && pessoasSearchMapDD04)} nomeBuscado={nomeBuscado} />
          <div id="DD05"></div><Cela cela="DD05" usuario={usuario} celaTotal={pessoasSearchMapDD05total} celaMap={(pessoasSearchMapDD05 && pessoasSearchMapDD05)} nomeBuscado={nomeBuscado} />
          <div id="DE01"></div><Cela cela="DE01" usuario={usuario} celaTotal={pessoasSearchMapDE01total} celaMap={(pessoasSearchMapDE01 && pessoasSearchMapDE01)} nomeBuscado={nomeBuscado} />
          <div id="DE02"></div><Cela cela="DE02" usuario={usuario} celaTotal={pessoasSearchMapDE02total} celaMap={(pessoasSearchMapDE02 && pessoasSearchMapDE02)} nomeBuscado={nomeBuscado} />
          <div id="DE03"></div><Cela cela="DE03" usuario={usuario} celaTotal={pessoasSearchMapDE03total} celaMap={(pessoasSearchMapDE03 && pessoasSearchMapDE03)} nomeBuscado={nomeBuscado} />
          <div id="DE04"></div><Cela cela="DE04" usuario={usuario} celaTotal={pessoasSearchMapDE04total} celaMap={(pessoasSearchMapDE04 && pessoasSearchMapDE04)} nomeBuscado={nomeBuscado} />
          <div id="DE05"></div><Cela cela="DE05" usuario={usuario} celaTotal={pessoasSearchMapDE05total} celaMap={(pessoasSearchMapDE05 && pessoasSearchMapDE05)} nomeBuscado={nomeBuscado} />
        </>}

        {pagina === 9 && <>
          <h1 ><strong>GALERIA "E"</strong>({totalSomaE})</h1>
          <div id="E01"></div><Cela cela="E01" usuario={usuario} celaTotal={pessoasSearchMapE01total} celaMap={(pessoasSearchMapE01 && pessoasSearchMapE01)} nomeBuscado={nomeBuscado} />
          <div id="E02"></div><Cela cela="E02" usuario={usuario} celaTotal={pessoasSearchMapE02total} celaMap={(pessoasSearchMapE02 && pessoasSearchMapE02)} nomeBuscado={nomeBuscado} />
          <div id="E03"></div><Cela cela="E03" usuario={usuario} celaTotal={pessoasSearchMapE03total} celaMap={(pessoasSearchMapE03 && pessoasSearchMapE03)} nomeBuscado={nomeBuscado} />
          <div id="E04"></div><Cela cela="E04" usuario={usuario} celaTotal={pessoasSearchMapE04total} celaMap={(pessoasSearchMapE04 && pessoasSearchMapE04)} nomeBuscado={nomeBuscado} />
          <div id="E05"></div><Cela cela="E05" usuario={usuario} celaTotal={pessoasSearchMapE05total} celaMap={(pessoasSearchMapE05 && pessoasSearchMapE05)} nomeBuscado={nomeBuscado} />
          <div id="E06"></div><Cela cela="E06" usuario={usuario} celaTotal={pessoasSearchMapE06total} celaMap={(pessoasSearchMapE06 && pessoasSearchMapE06)} nomeBuscado={nomeBuscado} />
          <div id="E07"></div><Cela cela="E07" usuario={usuario} celaTotal={pessoasSearchMapE07total} celaMap={(pessoasSearchMapE07 && pessoasSearchMapE07)} nomeBuscado={nomeBuscado} />
          <div id="E08"></div><Cela cela="E08" usuario={usuario} celaTotal={pessoasSearchMapE08total} celaMap={(pessoasSearchMapE08 && pessoasSearchMapE08)} nomeBuscado={nomeBuscado} />
          <div id="E09"></div><Cela cela="E09" usuario={usuario} celaTotal={pessoasSearchMapE09total} celaMap={(pessoasSearchMapE09 && pessoasSearchMapE09)} nomeBuscado={nomeBuscado} />
          <div id="E10"></div><Cela cela="E10" usuario={usuario} celaTotal={pessoasSearchMapE10total} celaMap={(pessoasSearchMapE10 && pessoasSearchMapE10)} nomeBuscado={nomeBuscado} />
          <div id="E11"></div><Cela cela="E11" usuario={usuario} celaTotal={pessoasSearchMapE11total} celaMap={(pessoasSearchMapE11 && pessoasSearchMapE11)} nomeBuscado={nomeBuscado} />
          <div id="E12"></div><Cela cela="E12" usuario={usuario} celaTotal={pessoasSearchMapE12total} celaMap={(pessoasSearchMapE12 && pessoasSearchMapE12)} nomeBuscado={nomeBuscado} />
          <div id="E13"></div><Cela cela="E13" usuario={usuario} celaTotal={pessoasSearchMapE13total} celaMap={(pessoasSearchMapE13 && pessoasSearchMapE13)} nomeBuscado={nomeBuscado} />
          <div id="E14"></div><Cela cela="E14" usuario={usuario} celaTotal={pessoasSearchMapE14total} celaMap={(pessoasSearchMapE14 && pessoasSearchMapE14)} nomeBuscado={nomeBuscado} />
          <div id="E15"></div><Cela cela="E15" usuario={usuario} celaTotal={pessoasSearchMapE15total} celaMap={(pessoasSearchMapE15 && pessoasSearchMapE15)} nomeBuscado={nomeBuscado} />
          <div id="E16"></div><Cela cela="E16" usuario={usuario} celaTotal={pessoasSearchMapE16total} celaMap={(pessoasSearchMapE16 && pessoasSearchMapE16)} nomeBuscado={nomeBuscado} />
          <div id="E17"></div><Cela cela="E17" usuario={usuario} celaTotal={pessoasSearchMapE17total} celaMap={(pessoasSearchMapE17 && pessoasSearchMapE17)} nomeBuscado={nomeBuscado} />
          <div id="E18"></div><Cela cela="E18" usuario={usuario} celaTotal={pessoasSearchMapE18total} celaMap={(pessoasSearchMapE18 && pessoasSearchMapE18)} nomeBuscado={nomeBuscado} />
          <div id="E19"></div><Cela cela="E19" usuario={usuario} celaTotal={pessoasSearchMapE19total} celaMap={(pessoasSearchMapE19 && pessoasSearchMapE19)} nomeBuscado={nomeBuscado} />
          <div id="E20"></div><Cela cela="E20" usuario={usuario} celaTotal={pessoasSearchMapE20total} celaMap={(pessoasSearchMapE20 && pessoasSearchMapE20)} nomeBuscado={nomeBuscado} />
        </>}
        {pagina === 10 && <>
          <h1 ><strong>GALERIA "F"</strong>({totalSomaF})</h1>
          <div id="F01"></div><Cela cela="F01" usuario={usuario} celaTotal={pessoasSearchMapF01total} celaMap={(pessoasSearchMapF01 && pessoasSearchMapF01)} nomeBuscado={nomeBuscado} />
          <div id="F02"></div><Cela cela="F02" usuario={usuario} celaTotal={pessoasSearchMapF02total} celaMap={(pessoasSearchMapF02 && pessoasSearchMapF02)} nomeBuscado={nomeBuscado} />
          <div id="F03"></div><Cela cela="F03" usuario={usuario} celaTotal={pessoasSearchMapF03total} celaMap={(pessoasSearchMapF03 && pessoasSearchMapF03)} nomeBuscado={nomeBuscado} />
          <div id="F04"></div><Cela cela="F04" usuario={usuario} celaTotal={pessoasSearchMapF04total} celaMap={(pessoasSearchMapF04 && pessoasSearchMapF04)} nomeBuscado={nomeBuscado} />
          <div id="F05"></div><Cela cela="F05" usuario={usuario} celaTotal={pessoasSearchMapF05total} celaMap={(pessoasSearchMapF05 && pessoasSearchMapF05)} nomeBuscado={nomeBuscado} />
          <div id="F06"></div><Cela cela="F06" usuario={usuario} celaTotal={pessoasSearchMapF06total} celaMap={(pessoasSearchMapF06 && pessoasSearchMapF06)} nomeBuscado={nomeBuscado} />
          <div id="F07"></div><Cela cela="F07" usuario={usuario} celaTotal={pessoasSearchMapF07total} celaMap={(pessoasSearchMapF07 && pessoasSearchMapF07)} nomeBuscado={nomeBuscado} />
          <div id="F08"></div><Cela cela="F08" usuario={usuario} celaTotal={pessoasSearchMapF08total} celaMap={(pessoasSearchMapF08 && pessoasSearchMapF08)} nomeBuscado={nomeBuscado} />
          <div id="F09"></div><Cela cela="F09" usuario={usuario} celaTotal={pessoasSearchMapF09total} celaMap={(pessoasSearchMapF09 && pessoasSearchMapF09)} nomeBuscado={nomeBuscado} />
          <div id="F10"></div><Cela cela="F10" usuario={usuario} celaTotal={pessoasSearchMapF10total} celaMap={(pessoasSearchMapF10 && pessoasSearchMapF10)} nomeBuscado={nomeBuscado} />
          <div id="F11"></div><Cela cela="F11" usuario={usuario} celaTotal={pessoasSearchMapF11total} celaMap={(pessoasSearchMapF11 && pessoasSearchMapF11)} nomeBuscado={nomeBuscado} />
          <div id="F12"></div><Cela cela="F12" usuario={usuario} celaTotal={pessoasSearchMapF12total} celaMap={(pessoasSearchMapF12 && pessoasSearchMapF12)} nomeBuscado={nomeBuscado} />
          <div id="F13"></div><Cela cela="F13" usuario={usuario} celaTotal={pessoasSearchMapF13total} celaMap={(pessoasSearchMapF13 && pessoasSearchMapF13)} nomeBuscado={nomeBuscado} />
          <div id="F14"></div><Cela cela="F14" usuario={usuario} celaTotal={pessoasSearchMapF14total} celaMap={(pessoasSearchMapF14 && pessoasSearchMapF14)} nomeBuscado={nomeBuscado} />
          <div id="F15"></div><Cela cela="F15" usuario={usuario} celaTotal={pessoasSearchMapF15total} celaMap={(pessoasSearchMapF15 && pessoasSearchMapF15)} nomeBuscado={nomeBuscado} />
          <div id="F16"></div><Cela cela="F16" usuario={usuario} celaTotal={pessoasSearchMapF16total} celaMap={(pessoasSearchMapF16 && pessoasSearchMapF16)} nomeBuscado={nomeBuscado} />
          <div id="F17"></div><Cela cela="F17" usuario={usuario} celaTotal={pessoasSearchMapF17total} celaMap={(pessoasSearchMapF17 && pessoasSearchMapF17)} nomeBuscado={nomeBuscado} />
          <div id="F18"></div><Cela cela="F18" usuario={usuario} celaTotal={pessoasSearchMapF18total} celaMap={(pessoasSearchMapF18 && pessoasSearchMapF18)} nomeBuscado={nomeBuscado} />
          <div id="F19"></div><Cela cela="F19" usuario={usuario} celaTotal={pessoasSearchMapF19total} celaMap={(pessoasSearchMapF19 && pessoasSearchMapF19)} nomeBuscado={nomeBuscado} />
          <div id="F20"></div><Cela cela="F20" usuario={usuario} celaTotal={pessoasSearchMapF20total} celaMap={(pessoasSearchMapF20 && pessoasSearchMapF20)} nomeBuscado={nomeBuscado} />
        </>}
        {pagina === 11 && <>
          <h1 ><strong>GALERIA "G"</strong>({totalSomaG})</h1>
          <div id="G01"></div><Cela cela="G01" usuario={usuario} celaTotal={pessoasSearchMapG01total} celaMap={(pessoasSearchMapG01 && pessoasSearchMapG01)} nomeBuscado={nomeBuscado} />
          <div id="G02"></div><Cela cela="G02" usuario={usuario} celaTotal={pessoasSearchMapG02total} celaMap={(pessoasSearchMapG02 && pessoasSearchMapG02)} nomeBuscado={nomeBuscado} />
          <div id="G03"></div><Cela cela="G03" usuario={usuario} celaTotal={pessoasSearchMapG03total} celaMap={(pessoasSearchMapG03 && pessoasSearchMapG03)} nomeBuscado={nomeBuscado} />
          <div id="G04"></div><Cela cela="G04" usuario={usuario} celaTotal={pessoasSearchMapG04total} celaMap={(pessoasSearchMapG04 && pessoasSearchMapG04)} nomeBuscado={nomeBuscado} />
          <div id="G05"></div><Cela cela="G05" usuario={usuario} celaTotal={pessoasSearchMapG05total} celaMap={(pessoasSearchMapG05 && pessoasSearchMapG05)} nomeBuscado={nomeBuscado} />
          <div id="G06"></div><Cela cela="G06" usuario={usuario} celaTotal={pessoasSearchMapG06total} celaMap={(pessoasSearchMapG06 && pessoasSearchMapG06)} nomeBuscado={nomeBuscado} />
          <div id="G07"></div><Cela cela="G07" usuario={usuario} celaTotal={pessoasSearchMapG07total} celaMap={(pessoasSearchMapG07 && pessoasSearchMapG07)} nomeBuscado={nomeBuscado} />
          <div id="G08"></div><Cela cela="G08" usuario={usuario} celaTotal={pessoasSearchMapG08total} celaMap={(pessoasSearchMapG08 && pessoasSearchMapG08)} nomeBuscado={nomeBuscado} />
          <div id="G09"></div><Cela cela="G09" usuario={usuario} celaTotal={pessoasSearchMapG09total} celaMap={(pessoasSearchMapG09 && pessoasSearchMapG09)} nomeBuscado={nomeBuscado} />
          <div id="G10"></div><Cela cela="G10" usuario={usuario} celaTotal={pessoasSearchMapG10total} celaMap={(pessoasSearchMapG10 && pessoasSearchMapG10)} nomeBuscado={nomeBuscado} />
          <div id="G11"></div><Cela cela="G11" usuario={usuario} celaTotal={pessoasSearchMapG11total} celaMap={(pessoasSearchMapG11 && pessoasSearchMapG11)} nomeBuscado={nomeBuscado} />
          <div id="G12"></div><Cela cela="G12" usuario={usuario} celaTotal={pessoasSearchMapG12total} celaMap={(pessoasSearchMapG12 && pessoasSearchMapG12)} nomeBuscado={nomeBuscado} />
          <div id="G13"></div><Cela cela="G13" usuario={usuario} celaTotal={pessoasSearchMapG13total} celaMap={(pessoasSearchMapG13 && pessoasSearchMapG13)} nomeBuscado={nomeBuscado} />
          <div id="G14"></div><Cela cela="G14" usuario={usuario} celaTotal={pessoasSearchMapG14total} celaMap={(pessoasSearchMapG14 && pessoasSearchMapG14)} nomeBuscado={nomeBuscado} />
          <div id="G15"></div><Cela cela="G15" usuario={usuario} celaTotal={pessoasSearchMapG15total} celaMap={(pessoasSearchMapG15 && pessoasSearchMapG15)} nomeBuscado={nomeBuscado} />
          <div id="G16"></div><Cela cela="G16" usuario={usuario} celaTotal={pessoasSearchMapG16total} celaMap={(pessoasSearchMapG16 && pessoasSearchMapG16)} nomeBuscado={nomeBuscado} />
          <div id="G17"></div><Cela cela="G17" usuario={usuario} celaTotal={pessoasSearchMapG17total} celaMap={(pessoasSearchMapG17 && pessoasSearchMapG17)} nomeBuscado={nomeBuscado} />
          <div id="G18"></div><Cela cela="G18" usuario={usuario} celaTotal={pessoasSearchMapG18total} celaMap={(pessoasSearchMapG18 && pessoasSearchMapG18)} nomeBuscado={nomeBuscado} />
          <div id="G19"></div><Cela cela="G19" usuario={usuario} celaTotal={pessoasSearchMapG19total} celaMap={(pessoasSearchMapG19 && pessoasSearchMapG19)} nomeBuscado={nomeBuscado} />
          <div id="G20"></div><Cela cela="G20" usuario={usuario} celaTotal={pessoasSearchMapG20total} celaMap={(pessoasSearchMapG20 && pessoasSearchMapG20)} nomeBuscado={nomeBuscado} />
        </>}
        {pagina === 12 && <>
          <h1 ><strong>GALERIA "H"</strong>({totalSomaH})</h1>

          <div id="HD01"></div><Cela cela="HD01" usuario={usuario} celaTotal={pessoasSearchMapHD01total} celaMap={(pessoasSearchMapHD01 && pessoasSearchMapHD01)} nomeBuscado={nomeBuscado} />
          <div id="HD02"></div><Cela cela="HD02" usuario={usuario} celaTotal={pessoasSearchMapHD02total} celaMap={(pessoasSearchMapHD02 && pessoasSearchMapHD02)} nomeBuscado={nomeBuscado} />
          <div id="HD03"></div><Cela cela="HD03" usuario={usuario} celaTotal={pessoasSearchMapHD03total} celaMap={(pessoasSearchMapHD03 && pessoasSearchMapHD03)} nomeBuscado={nomeBuscado} />
          <div id="HD04"></div><Cela cela="HD04" usuario={usuario} celaTotal={pessoasSearchMapHD04total} celaMap={(pessoasSearchMapHD04 && pessoasSearchMapHD04)} nomeBuscado={nomeBuscado} />
          <div id="HD05"></div><Cela cela="HD05" usuario={usuario} celaTotal={pessoasSearchMapHD05total} celaMap={(pessoasSearchMapHD05 && pessoasSearchMapHD05)} nomeBuscado={nomeBuscado} />
          <div id="HE01"></div><Cela cela="HE01" usuario={usuario} celaTotal={pessoasSearchMapHE01total} celaMap={(pessoasSearchMapHE01 && pessoasSearchMapHE01)} nomeBuscado={nomeBuscado} />
          <div id="HE02"></div><Cela cela="HE02" usuario={usuario} celaTotal={pessoasSearchMapHE02total} celaMap={(pessoasSearchMapHE02 && pessoasSearchMapHE02)} nomeBuscado={nomeBuscado} />
          <div id="HE03"></div><Cela cela="HE03" usuario={usuario} celaTotal={pessoasSearchMapHE03total} celaMap={(pessoasSearchMapHE03 && pessoasSearchMapHE03)} nomeBuscado={nomeBuscado} />
          <div id="HE04"></div><Cela cela="HE04" usuario={usuario} celaTotal={pessoasSearchMapHE04total} celaMap={(pessoasSearchMapHE04 && pessoasSearchMapHE04)} nomeBuscado={nomeBuscado} />
          <div id="HE05"></div><Cela cela="HE05" usuario={usuario} celaTotal={pessoasSearchMapHE05total} celaMap={(pessoasSearchMapHE05 && pessoasSearchMapHE05)} nomeBuscado={nomeBuscado} />
        </>}
        {pagina === 13 && <>
          <h1 ><strong>ALOJAMENTO</strong>({pessoasSearchMapALOJAMENTOtotal})</h1>

          <div id="ALOJAMENTO"></div><Cela cela="ALOJAMENTO" usuario={usuario} celaTotal={pessoasSearchMapALOJAMENTOtotal} celaMap={(pessoasSearchMapALOJAMENTO && pessoasSearchMapALOJAMENTO)} nomeBuscado={nomeBuscado} />

        </>}
        {pagina === 6 && <>
          <h1 ><strong>TRIAGEM "TR"</strong>({totalSomaTR})</h1>

          <div id="TR"></div><Cela usuario={usuario} cela="TR" celaTotal={pessoasSearchMapTRtotal} celaMap={(pessoasSearchMapTR && pessoasSearchMapTR)} nomeBuscado={nomeBuscado} />
          <div id="TR01"></div><Cela usuario={usuario} cela="TR01" celaTotal={pessoasSearchMapTR01total} celaMap={(pessoasSearchMapTR01 && pessoasSearchMapTR01)} nomeBuscado={nomeBuscado} />
          <div id="TR02"></div><Cela usuario={usuario} cela="TR02" celaTotal={pessoasSearchMapTR02total} celaMap={(pessoasSearchMapTR02 && pessoasSearchMapTR02)} nomeBuscado={nomeBuscado} />
          <div id="TR03"></div><Cela usuario={usuario} cela="TR03" celaTotal={pessoasSearchMapTR03total} celaMap={(pessoasSearchMapTR03 && pessoasSearchMapTR03)} nomeBuscado={nomeBuscado} />
          <div id="TR04"></div><Cela usuario={usuario} cela="TR04" celaTotal={pessoasSearchMapTR04total} celaMap={(pessoasSearchMapTR04 && pessoasSearchMapTR04)} nomeBuscado={nomeBuscado} />
          <div id="TR05"></div><Cela usuario={usuario} cela="TR05" celaTotal={pessoasSearchMapTR05total} celaMap={(pessoasSearchMapTR05 && pessoasSearchMapTR05)} nomeBuscado={nomeBuscado} />
          <div id="TR06"></div><Cela usuario={usuario} cela="TR06" celaTotal={pessoasSearchMapTR06total} celaMap={(pessoasSearchMapTR06 && pessoasSearchMapTR06)} nomeBuscado={nomeBuscado} />
        </>}

        {pagina === 7 && <> <h1><strong> PRESOS FORA DA UNIDADE</strong></h1>

          <br></br><br></br>
          <div id="PRISÃO DOMICILIAR"></div> <Cela usuario={usuario} cela="PRISÃO DOMICILIAR" celaTotal={pessoasDomiciliartotal} celaMap={(pessoasDomiciliar && pessoasDomiciliar)} />

          <div id="INTERNAÇÃO HOSPITALAR"></div> <Cela usuario={usuario} cela="INTERNAÇÃO HOSPITALAR" presoForaTitulo={1} celaTotal={pessoasHospitaltotal} celaMap={(pessoasHospital && pessoasHospital)} />

          <div id="PERNOITE FORA"></div><Cela usuario={usuario} cela="PERNOITE FORA" presoForaTitulo={1} celaTotal={pessoasPernoitetotal} celaMap={(pessoasPernoite && pessoasPernoite)} />


          <br />


        </>}


      </div>
      <br></br><br></br><br></br>
    </div>
  </>
  )
}

export default Home